import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import "./ModalContent.scss";

export default function ModalContent({
    size = undefined,
    onHideCallbackSecondary = () => { },
    onHideCallbackPrimary = () => { },
    onHideCallbackThird = () => { },
    primaryButton = null,
    secondaryButton = null,
    thirdButton = null,
    ...props
}: ModalContentProperties) {
    const { t } = useTranslation();
    const [show, setShow] = useState(props.show);

    useEffect(() => {
        setShow(props.show);
    }, [props.show]);

    return (
        <>
            <Modal
                {...size === undefined ? { fullscreen: true } : { size: size }}
                show={show}
                onHide={() => {
                    setShow(false);
                    onHideCallbackSecondary && onHideCallbackSecondary();
                    props.onClose && props.onClose();
                }}
                centered
            >
                <Modal.Header closeButton>
                    {props.title != null ? <Modal.Title>{t(props.title)}</Modal.Title> : null}
                </Modal.Header>
                <Modal.Body>{props.children}</Modal.Body>

                {/* only display footer if buttons are available */}
                {primaryButton != null || secondaryButton != null || thirdButton != null ? <Modal.Footer>
                    <div className="buttons">
                        {primaryButton != null && (
                            <button
                                className="btn btn-primary me-3"
                                onClick={() => {
                                    setShow(false);
                                    onHideCallbackPrimary && onHideCallbackPrimary();
                                }}
                            >
                                {t(primaryButton)}
                            </button>
                        )}

                        {thirdButton != null && (
                            <button
                                className="btn btn-warning me-3"
                                onClick={() => {
                                    setShow(false);
                                    onHideCallbackThird && onHideCallbackThird();
                                }}
                            >
                                {t(thirdButton)}
                            </button>
                        )}

                        {secondaryButton != null && (
                            <button
                                className="btn btn-secondary"
                                onClick={() => {
                                    setShow(false);
                                    onHideCallbackSecondary && onHideCallbackSecondary();
                                }}
                            >
                                {t(secondaryButton)}
                            </button>
                        )}

                    </div>
                </Modal.Footer>
                    : null}
            </Modal>
        </>
    );
}

interface ModalContentProperties {
    title?: string;
    size: "sm" | "lg" | "xl" | undefined;
    children: any;
    show: boolean;
    onHideCallbackSecondary?: () => void;
    onHideCallbackPrimary?: () => void;
    onHideCallbackThird?: () => void;
    primaryButton?: string | null;
    secondaryButton?: string | null;
    thirdButton?: string | null;
    onClose?: () => void;
}