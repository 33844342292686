import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import common_en from '../translations/en.json';
import common_de from '../translations/de.json';
import enum_medical_jobs_en from '../translations/enum medical jobs/en_enum_medical_jobs.json';
import enum_medical_jobs_de from '../translations/enum medical jobs/de_enum_medical_jobs.json';
import enum_userdata_en from '../translations/enum userdata/en_enum_userdata.json';
import enum_userdata_de from '../translations/enum userdata/de_enum_userdata.json'
import terms_en from '../translations/general terms and conditions/en_terms.json';
import terms_de from '../translations/general terms and conditions/de_terms.json';
import imprint_en from '../translations/imprint/en_imprint.json';
import imprint_de from '../translations/imprint/de_imprint.json';
import privacy_en from '../translations/privacy policy/en_privacy.json';
import privacy_de from '../translations/privacy policy//de_privacy.json';
import fulluserpage_en from '../translations/fulluserpage/en_fulluserpage.json';
import fulluserpage_de from '../translations/fulluserpage/de_fulluserpage.json';
import login_register_en from '../translations/login register/en_login_register.json';
import login_register_de from '../translations/login register/de_login_register.json';
import static_en from '../translations/static pages/en_static.json';
import static_de from '../translations/static pages/de_static.json';
import chat_en from '../translations/chat/en_chat.json';
import chat_de from '../translations/chat/de_chat.json';
import joboffer_en from '../translations/joboffer/en_joboffer.json';
import joboffer_de from '../translations/joboffer/de_joboffer.json';
import support_en from '../translations/support/en_support.json';
import support_de from '../translations/support/de_support.json';
import country_en from '../translations/country/en_country.json';
import country_de from '../translations/country/de_country.json';
import state_de from '../translations/state/de_state.json';
import state_en from '../translations/state/en_state.json';

import LanguageDetector from 'i18next-browser-languagedetector';
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

const resources = {
    en: { translation: {...common_en, ...enum_medical_jobs_en, ...terms_en, ...imprint_en, ...privacy_en,
        ...enum_userdata_en, ...static_en, ...fulluserpage_en, ...login_register_en, ...chat_en, ...joboffer_en, ...support_en,
        ...country_en, ...state_en }},
    de: { translation: {...common_de, ...enum_medical_jobs_de, ...terms_de, ...imprint_de, ...privacy_de,
        ...enum_userdata_de, ...static_de, ...fulluserpage_de, ...login_register_de, ...chat_de, ...joboffer_de, ...support_de,
        ... country_de, ...state_de }},
}

i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
    // .use(Backend)

    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)

    // pass the i18n instance to react-i18next.
    .use(initReactI18next)

    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        resources,
        // lng: 'de', // is not needed because we read the lng from the current browser setting
        fallbackLng: 'de',
        debug: true,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        }
    });


export default i18n;