import reCREWterLogo from "../../img/logo_horizontal.png";
import LoggedInUser from "../LoggedInUser";
import { checkLoggedInStatus } from "../../core/claimFunctions";
import AuthenticationContext from "../../auth/AuthenticationContext";
import React, { useContext, useEffect, useState } from "react";
import { Nav, Navbar } from "react-bootstrap";
import "./Mainmenu.scss";

export default function Mainmenu() {

    const { claims } = useContext(AuthenticationContext);
    const [loggedIn, setLoggedIn] = useState(checkLoggedInStatus(claims));

    useEffect(() => {
        setLoggedIn(checkLoggedInStatus(claims))
    }, [claims]);

    return (
        <div className="menuContainer">
            <Navbar expand="lg">
                {
                    loggedIn
                    ?
                    <>
                        <Navbar.Brand href="/dashboard">
                            <img src={reCREWterLogo} alt="Digital Recrewter Logo" className="menuImageLogo" />
                        </Navbar.Brand>
                        <Navbar.Collapse >
                            <Nav>
                                {/* <IconMenuLink classLink="nav-link" classImage="icon" whereTo="/home" translation="menuHome" />
                                <IconMenuLink classLink="nav-link" classImage="icon" whereTo="/employee" translation="menuEmployee" />
                                <IconMenuLink classLink="nav-link" classImage="icon" whereTo="/recrewter" translation="menuEmployer" />
                                <IconMenuLink classLink="nav-link" classImage="icon" whereTo="/aboutus" translation="menuAboutUs" />
                                <IconMenuLink classLink="nav-link" classImage="icon" whereTo="/contact" translation="menuContact" /> */}
                                <LoggedInUser />
                            </Nav>
                        </Navbar.Collapse>
                    </>
                    :
                    <>
                        <Navbar.Brand href="/">
                            <img src={reCREWterLogo} alt="Digital Recrewter Logo" className="menuImageLogo" />
                        </Navbar.Brand>
                        <Navbar.Collapse >
                            <Nav>
                                {/* <IconMenuLink classLink="nav-link" classImage="icon" whereTo="/home" translation="menuHome" />
                                <IconMenuLink classLink="nav-link" classImage="icon" whereTo="/employee" translation="menuEmployee" />
                                <IconMenuLink classLink="nav-link" classImage="icon" whereTo="/recrewter" translation="menuEmployer" />
                                <IconMenuLink classLink="nav-link" classImage="icon" whereTo="/aboutus" translation="menuAboutUs" />
                                <IconMenuLink classLink="nav-link" classImage="icon" whereTo="/contact" translation="menuContact" /> */}
                                {/* <IconMenuLink classLink="nav-link loginLink" whereTo="/auth" translation="login" /> */}
                            </Nav>
                        </Navbar.Collapse>
                    </>
                }
            </Navbar>
        </div>
    )
}