import React, { useEffect, useState } from 'react';
import { useRoutes } from "react-router-dom";
import { getClaims } from "./core/handleJWT";
import { UserRoles } from './config/Types/GeneralEnumDefinitions';
import { claim } from './core/dto/auth.models';
import AuthenticationContext from './auth/AuthenticationContext';
import configureInterceptors from "./utils/httpInterceptors";
import Authorized from './auth/Authorized';
import AuthPage from './auth/AuthPage';
import PrivacyPolicy from './pages/static/components/PrivacyPolicy';
import TermsAndConditions from './pages/static/components/TermsAndConditions';
import Imprint from './pages/static/components/Imprint';
import EmailConfirmed from './pages/static/EmailConfirmed';
import Chat from './pages/Chat';
import CV from './components/CV/CV';
import Jobs from './pages/Jobs';
import Personal from './pages/Personal';
import Footer from './components/Footer/Footer';
import AuthLoginPage from './auth/AuthLoginPage';
import AuthRegisterPage from './auth/AuthRegisterPage';
import RedirectToHome from './utils/RedirectToHome';
import FullUserPage from './components/Settings/FullUserPage';
import AuthorizedExtended from './auth/AuthorizedExtended';
import './customBootstrap.scss';
import './index.scss';
import JobOffer from './pages/JobOffer';
import AuthSSOPage from './auth/AuthSSOPage';
import EmailNotVerified from './pages/static/EmailNotVerified';
import FeedbackToast from './utils/FeedbackToast';
import { WebSocketProvider } from './contexts/WebSocketContext';
import { ImageProvider } from './contexts/ImageContext';

configureInterceptors();

declare global {
    interface Window {
        umami: any;
    }
}

export default function App() {

    const [claims, setClaims] = useState<claim[]>([]);
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState("");
    const [mode, setMode] = useState(0);
    const [duration, setDuration] = useState(0);

    const update = (newClaims: claim[]) => {
        setClaims(newClaims);
    }

    useEffect(() => {
        setClaims(getClaims());
    }, []);

    let routes = useRoutes([

        // Login and register
        { path: '/auth', element: <Authorized authorized={<AuthPage />} notAuthorized={<AuthPage />} /> },
        { path: '/login', element: <Authorized authorized={<AuthPage />} notAuthorized={<AuthLoginPage />} /> },
        { path: '/register', element: <Authorized authorized={<AuthPage />} notAuthorized={<AuthRegisterPage />} /> },
        { path: '/sso', element: <Authorized authorized={<AuthSSOPage />} notAuthorized={<AuthSSOPage />} /> },

        // Public pages
        { path: '/imprint', element: <Imprint /> },
        { path: '/policy', element: <PrivacyPolicy /> },
        { path: '/terms', element: <TermsAndConditions /> },

        // Menus
        { path: '/chat/:participantId/:contentForMessage', element: <AuthorizedExtended authorized={<Chat />} notAuthorized={<FullUserPage />} relogIn={<AuthPage />} roles={[UserRoles.Employee, UserRoles.Recrewter, UserRoles.Admin]} /> },
        { path: '/chat/:participantId', element: <AuthorizedExtended authorized={<Chat />} notAuthorized={<FullUserPage />} relogIn={<AuthPage />} roles={[UserRoles.Employee, UserRoles.Recrewter, UserRoles.Admin]} /> },
        { path: '/chat', element: <AuthorizedExtended authorized={<Chat />} notAuthorized={<FullUserPage />} relogIn={<AuthPage />} roles={[UserRoles.Employee, UserRoles.Recrewter, UserRoles.Admin]} /> },
        { path: '/jobs', element: <AuthorizedExtended authorized={<Jobs />} notAuthorized={<FullUserPage />} relogIn={<AuthPage />} roles={[UserRoles.Employee, UserRoles.Recrewter, UserRoles.Admin]} /> },
        { path: '/jobs/:jobofferId', element: <AuthorizedExtended authorized={<JobOffer />} notAuthorized={<FullUserPage />} relogIn={<AuthPage />} roles={[UserRoles.Employee, UserRoles.Recrewter, UserRoles.Admin]} /> },
        { path: '/personal', element: <AuthorizedExtended authorized={<Personal />} notAuthorized={<FullUserPage />} relogIn={<AuthPage />} roles={[UserRoles.Recrewter, UserRoles.Admin]} /> },
        { path: '/cv/:userId', element: <AuthorizedExtended authorized={<CV />} notAuthorized={<FullUserPage />} relogIn={<AuthPage />} roles={[UserRoles.Recrewter, UserRoles.Employee, UserRoles.Admin]} /> },
        { path: '/settings', element: <AuthorizedExtended authorized={<FullUserPage />} notAuthorized={<FullUserPage />} relogIn={<AuthPage />} roles={[UserRoles.Employee, UserRoles.Recrewter, UserRoles.Admin]} /> },

        // Dashboard
        // the dashboard does not exist anymore, but we are keeping the path because the default page for recrewter and employee are not the same. this way we can just reroute to /dashboard
        { path: '/dashboard', element: <AuthorizedExtended authorized={<Personal />} notAuthorized={<Jobs />} relogIn={<AuthPage />} roles={[UserRoles.Recrewter, UserRoles.Admin]} /> },

        // Infos
        { path: '/confirmEmail', element: <EmailConfirmed /> },
        { path: '/verifyEmail', element: <EmailNotVerified /> },

        // { path: '/', element: <Home /> },
        { path: '/', element: <AuthPage /> },
        { path: '*', element: <RedirectToHome /> },
    ]);

    return (
        <AuthenticationContext.Provider
            value={{
                claims,
                update,
                success,
                setSuccess,
                message,
                setMessage,
                mode,
                setMode,
                duration,
                setDuration
            }}>
            <WebSocketProvider>
                <ImageProvider>
                    <div id="content-wrap">
                        {routes}
                    </div>
                    <FeedbackToast />
                    <Footer />
                </ImageProvider>
            </WebSocketProvider>
        </AuthenticationContext.Provider>
    )
}
