import React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function Pagination({
    radius = 2,
    ...props
}: paginationProps) {
    const [linkModels, setLinkModels] = useState<linkModel[]>([]);
    const { t } = useTranslation();

    function selectPage(link: linkModel) {
        if (link.page === props.currentPage || !link.enabled) {
            return;
        }

        props.onChange(link.page);
    }

    function getClass(link: linkModel) {
        if (link.active) {
            return "active pointer";
        }

        if (!link.enabled) {
            return "disabled";
        }

        return "pointer";
    }

    useEffect(() => {
        const links: linkModel[] = [];

        const previousPageEnabled = props.currentPage !== 1;
        const previousPage = props.currentPage - 1;
        links.push({
            text: t("previous"),
            page: previousPage,
            enabled: previousPageEnabled,
            active: false
        });

        if (props.currentPage > radius + 1) {
            links.push({
                text: "1",
                page: 1,
                enabled: true,
                active: false
            });
            if (props.currentPage > radius + 2) {
                links.push({
                    text: "...",
                    page: props.currentPage - radius - 1,
                    enabled: false,
                    active: false
                });
            }
        }

        for (let i = Math.max(1, props.currentPage - radius); i <= Math.min(props.totalAmountOfPages, props.currentPage + radius); i++) {
            links.push({
                text: `${i}`,
                page: i,
                enabled: true,
                active: props.currentPage === i
            });
        }

        if (props.currentPage < props.totalAmountOfPages - radius) {
            if (props.currentPage < props.totalAmountOfPages - radius - 1) {
                links.push({
                    text: "...",
                    page: props.currentPage + radius + 1,
                    enabled: false,
                    active: false
                });
            }
            links.push({
                text: `${props.totalAmountOfPages}`,
                page: props.totalAmountOfPages,
                enabled: true,
                active: false
            });
        }

        const nextPageEnabled = props.currentPage !== props.totalAmountOfPages && props.totalAmountOfPages > 0;
        const nextPage = props.currentPage + 1;
        links.push({
            text: t("next"),
            page: nextPage,
            enabled: nextPageEnabled,
            active: false
        });

        setLinkModels(links);
    }, [props.currentPage, props.totalAmountOfPages, radius, t]);

    return (
        <nav>
            <ul className="pagination justify-content-center">
                {linkModels.map(link =>
                    <li key={link.text} onClick={() => selectPage(link)}
                        className={`page-item cursor ${getClass(link)}`}>
                        <span className="page-link">{link.text}</span>
                    </li>)}
            </ul>
        </nav>
    );
}

interface paginationProps {
    currentPage: number;
    totalAmountOfPages: number;
    radius: number;
    onChange(page: number): void;
}

interface linkModel {
    page: number;
    enabled: boolean;
    text: string;
    active: boolean;
}