import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import React from 'react';
import { Form, Formik, FormikErrors } from 'formik';
import FormikTextField from '../utils/FormikTextField';
import * as yup from 'yup'
import Button from '../utils/Button';
import DisplayErrors from '../utils/DisplayErrors';
import Mainmenu from '../components/Menu/Mainmenu';
import Header from '../components/Header/Header';
import { useTranslation } from 'react-i18next';
import { existsUserAPI, isEmailConfirmed } from '../core/apiFunctions';
import { ssoURL } from '../config/endpoints';
import { GoogleLoginButton, LinkedInLoginButton } from "react-social-login-buttons";
import ModalContent from '../utils/ModalContent';
import ResetPasswordContent from './ResetPasswordContent';
import { checkLoggedInStatus } from '../core/claimFunctions';
import { getClaims } from '../core/handleJWT';
import './AuthPage.scss'

export default function AuthPage() {

    const navigation = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");
    const email = queryParams.get("email");

    const [showResetPasswordModel, setShowResetPasswordModel] = useState(!!token && !!email);

    const [errors, setErrors] = useState<string[]>([]);

    const { t } = useTranslation();

    useEffect(() => {
        const claims = getClaims();
        if(checkLoggedInStatus(claims)){
            navigation('/dashboard');
        }
    }, []);

    useEffect(() => {
        const state = location.state as { error: string };
        if (state && state.error) {
            setErrors([state.error]);
        }
    }, [location, navigation]);

    async function submit(email: string) {
        try {
            setErrors([]);

            const response = await existsUserAPI(email);
            if (response.data.sso) {
                setErrors([t("ssoExists")]);
            }
            else {
                var isEmailVerified = await isEmailConfirmed(email);
                if (response.data.exists && isEmailVerified) {
                    navigation('/login', { state: { email: email, role: response.data.role } });
                } else if (response.data.exists && !isEmailVerified) {
                    navigation('/verifyEmail');
                }
                else {
                    navigation('/register', { state: { email: email } });
                }
            }
        }
        catch (error: any) {
            setErrors(error.response.data);
        }
    }

    function handleSSOLogin(provider: string, setErrors: { (errors: FormikErrors<{ email: string; }>): void; (errors: FormikErrors<{ email: string; }>): void; (arg0: {}): void; }) {
        setErrors({});
        const returnUrl = encodeURIComponent(`${window.location.origin}/sso`);
        window.location.href = `${ssoURL}/${provider}?returnUrl=${returnUrl}`;
    }

    return (
        <>
            {
                showResetPasswordModel &&
                <ModalContent
                    size="lg"
                    show={showResetPasswordModel}
                    children={<ResetPasswordContent handleCloseModal={() => setShowResetPasswordModel(false)} email={email!} token={token!} />}
                    title={t("resetPassword")}
                />
            }

            <Mainmenu />
            <Header title={t("log_register")}
                text=""
                imageUrl="doctors-walking-away.png" />

            <div className="authContainer">
                <Formik
                    initialValues={{ email: '' }}
                    onSubmit={async values => await submit(values.email)}
                    validationSchema={yup.object({
                        // validation for email
                        email: yup.string()
                            .required(t("emailRequired"))
                            .email(t("emailNotValid"))
                    })}
                    validateOnBlur={false}>

                    {formikProps => (
                        <>
                            <Form className="login">
                                <FormikTextField displayName={"email"} field="email" autoFocus={true} />
                                <DisplayErrors errors={errors} />

                                <Button class="btn btn-primary mb-3 login-button" disabled={formikProps.isSubmitting} type="submit">{t("btnLoginRegister")}</Button>

                                <div className="or-separator">{t("or")}</div>

                                <GoogleLoginButton onClick={() => handleSSOLogin('google', formikProps.setErrors)} />
                                <LinkedInLoginButton onClick={() => handleSSOLogin('linkedin', formikProps.setErrors)} />

                            </Form>
                        </>
                    )}

                </Formik>
            </div>
        </>
    )
}
