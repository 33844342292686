import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getAboutMeAPI, getDreamJobDescriptionAPI, getCompanyDetailsAPI } from "../../core/apiFunctions";

export default function CVUserText({
    isEmployee = true,
    ...props
}: CVAboutUserProperties) {
    const [aboutUser, setAboutUser] = useState<String>();
    const [dreamJobDescription, setDreamJobDescription] = useState<String>();
    const [aboutCompany, setAboutCompany] = useState<String>();
    const { t } = useTranslation();

    useEffect(() => {
        if (isEmployee) {

            if (props.isAboutMe){
                const fetchAboutUser = async () => {
                    const aboutUser = await getAboutMeAPI(props.userId);
                    setAboutUser(aboutUser.data.aboutMe);
                }
                fetchAboutUser();
            }
            else {
                const fetchDreamJobDescription = async () => {
                    const dreamJobDescription = await getDreamJobDescriptionAPI(props.userId);
                    setDreamJobDescription(dreamJobDescription.data);
                }
                fetchDreamJobDescription();
            }
        }
        else {
            const fetchAboutCompany = async () => {
                getCompanyDetailsAPI(props.userId).then(response => {
                    setAboutCompany(response.data.description);
                });
            }
            fetchAboutCompany();
        }
    }, []);


    return (
        <>
            <div className="cvCard">
                <div className="cvCardHeader">
                    <p>{isEmployee ?
                        (
                            props.isAboutMe ?
                                t('aboutMe')
                            :
                                t('myDreamJobDescription')
                        )
                    :
                        t('description')}</p>
                </div>
                {
                    isEmployee ?
                        (
                            props.isAboutMe ?
                                <pre>{aboutUser}</pre>
                            :
                                <pre>{dreamJobDescription}</pre>
                        )
                    :
                        <pre>{aboutCompany}</pre>
                }
            </div>
        </>
    )
}

interface CVAboutUserProperties {
    userId: string;
    isEmployee?: boolean;
    isAboutMe?: boolean;
}