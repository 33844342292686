import React, { useState } from "react";
import { submitFileAPI } from "../../core/apiFunctions";
import { FileType, ImageType } from "../../config/Types/GeneralEnumDefinitions";
import Button from "../../utils/Button";
import { useTranslation } from "react-i18next";
import { faSave, faUndo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fileUploadDTO } from "../../core/dto/user.models";
import DisplayErrors from "../../utils/DisplayErrors";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useProfileImage } from "../../contexts/ImageContext";

interface ProfileHeaderProps {
    profilePhotoType: FileType;
    headerPhotoType: FileType;
    apiCallToSubmitFile: (dto: fileUploadDTO) => Promise<any>;
}

export default function ProfileHeader(props: ProfileHeaderProps) {
    const { t } = useTranslation();
    const { images, setImage, saveImage, cancelImageChange } = useProfileImage();
    const [headerFile, setHeaderFile] = useState<File>();
    const [profileFile, setProfileFile] = useState<File>();
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [newHeaderPhoto, setNewHeaderPhoto] = useState<boolean>(false);
    const [newProfilePhoto, setNewProfilePhoto] = useState<boolean>(false);
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [errors, setErrors] = useState<string[]>([]);

    const openUploadDialog = (
        setNewImageFlag: (status: boolean) => void,
        setFile: (file: File) => void,
        setPhoto: (photo: string) => void,
        imageType: ImageType
    ) => {
        const input = document.createElement("input");
        input.type = "file";
        input.accept = "image/*";
        input.onchange = (e: any) => {
            const file = e.target.files[0];
            if (validate(file)) {
                setIsEditing(true);
                const url = URL.createObjectURL(file);
                setPhoto(url);
                setFile(file);
                setNewImageFlag(true);
                setImage(imageType, url);
            }
        };
        input.click();
    };

    const validate = (file: File) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            alert('You can only upload JPG/PNG file!');
            return false;
        }

        const isLt25M = file.size / 1024 / 1024 < 25;
        if (!isLt25M) {
            alert('Image must be smaller than 25MB!');
            return false;
        }
        return isJpgOrPng && isLt25M;
    };

    const upload = () => {
        setIsUploading(true);
        const promises: Promise<any>[] = [];

        if (newHeaderPhoto) {
            const fileUploadDto = { file: headerFile!, type: props.headerPhotoType };
            promises.push(
                props.apiCallToSubmitFile(fileUploadDto)
                    .then(() => {
                        saveImage(ImageType.HeaderImage);
                        setNewHeaderPhoto(false);
                    })
            );
        }

        if (newProfilePhoto) {
            const fileUploadDto = { file: profileFile!, type: props.profilePhotoType };
            promises.push(
                props.apiCallToSubmitFile(fileUploadDto)
                    .then(() => {
                        saveImage(ImageType.ProfileImage);
                        setNewProfilePhoto(false);
                    })
            );
        }

        Promise.all(promises)
            .then(() => setIsEditing(false))
            .catch(error => setErrors([error.message]))
            .finally(() => setIsUploading(false));
    };

    const resetToOriginal = () => {
        cancelImageChange(ImageType.HeaderImage);
        cancelImageChange(ImageType.ProfileImage);
        setNewHeaderPhoto(false);
        setNewProfilePhoto(false);
        setIsEditing(false);
        setErrors([]);
    };

    return (
        <>
            <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="tooltip">{t("recommendedMinHeader")}</Tooltip>}
            >
                <img src={images[ImageType.HeaderImage]} className="header-picture" alt="header"
                    onClick={() => openUploadDialog(setNewHeaderPhoto, setHeaderFile, setImage.bind(null, ImageType.HeaderImage), ImageType.HeaderImage)} />
            </OverlayTrigger>
            <div className="row-center">
                <div className="image"
                    onClick={() => openUploadDialog(setNewProfilePhoto, setProfileFile, setImage.bind(null, ImageType.ProfileImage), ImageType.ProfileImage)}>
                    <img src={images[ImageType.ProfileImage]} className="image_img" alt="avatar" />
                    <div className="image_overlay image_overlay-primary">
                        <p className="image_description">
                            {t("uploadPhoto")}
                        </p>
                    </div>
                </div>
            </div>
            <div className="row-center mt-4">
                <Button class="btn btn-primary" hidden={!isEditing} type="button" onClick={upload} disabled={isUploading}>
                    {isUploading ?
                        <div className="spinner-border spinner-border-sm" role="status" />
                        :
                        <FontAwesomeIcon icon={faSave} />
                    } {t("save")}
                </Button>
                <Button class="btn btn-danger ms-3" hidden={!isEditing} type="button"
                    onClick={resetToOriginal}>
                    <FontAwesomeIcon icon={faUndo} /> {t('cancel')}
                </Button>
            </div>
            <DisplayErrors errors={errors} />
        </>
    );
}
