import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import React, { useContext, useEffect, useRef, useState } from "react";
import { faBars, faClose } from "@fortawesome/free-solid-svg-icons";
import Authorized from "../../auth/Authorized";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IconMenuLink from "../../utils/IconMenuLink";
import LanguageSwitch from "../Language Switch/LanguageSwitch";
import { UserRoles } from "../../config/Types/GeneralEnumDefinitions";
import chatIcon from "../../img/icon/chat.png";
import jobsIcon from "../../img/icon/jobs.png";
import premiumIcon from "../../img/icon/premium_purple.png";
import staffIcon from "../../img/icon/personal.png";
import supportIcon from "../../img/icon/ask.png";
import settingsIcon from "../../img/icon/settings.png";
import logoutIcon from "../../img/icon/logout.png";
import { t } from "i18next";
import "./PlattformMenu.scss";
import { logout } from "../../core/handleJWT";
import { getAdminIdAPI, getUnreadMessageCountAPI } from "../../core/apiFunctions";
import { useLocation } from 'react-router-dom';
import useFeedback from "../../utils/useFeedback";
import WebSocketContext from "../../contexts/WebSocketContext";
import AuthenticationContext from "../../auth/AuthenticationContext";
import { checkRole } from "../../core/claimFunctions";

export default function PlattformMenu() {
    const menuRef = useRef<HTMLDivElement>(null);
    const [isMobile] = React.useState(window.innerWidth <= 768);
    const [adminId, setAdminId] = React.useState<string>("");
    const [unreadMessageCount, setUnreadMessageCount] = useState(0);
    const location = useLocation();
    const { showSuccess } = useFeedback();
    const { messageReceivedSignalR } = useContext(WebSocketContext)!;
    const { claims } = useContext(AuthenticationContext);

    useEffect(() => {
        if((!location.pathname.endsWith('/chat'))) {
            try {
                getUnreadMessageCountAPI().then((response) => {
                    const newUnreadMessageCount = response.data;
                    if (newUnreadMessageCount > unreadMessageCount) {
                        const messageKey = newUnreadMessageCount === 1 ? 'newMessage' : 'newMessages';
                        showSuccess(t(messageKey, { count: newUnreadMessageCount }));
                    }
                    setUnreadMessageCount(newUnreadMessageCount);
                });
            } catch (error) {
                throw null;
            }
        }
    }, [messageReceivedSignalR]);

    useEffect(() => {
        getAdminIdAPI().then((res) => {
            setAdminId(res.data);
        });
    }, []);

    const toggleMobileMenu = () => {
        if (!menuRef.current?.classList.contains('customNavBarShowen')) {
            menuRef.current?.classList.add('customNavBarShowen');
        } else {
            menuRef.current?.classList.remove('customNavBarShowen');
        }
    }

    return (
        <div className="menuContainer customNavBarContainer" ref={menuRef}>
            <button onClick={toggleMobileMenu}>
                <FontAwesomeIcon icon={faBars} />
            </button>
            <div className="customNavBarBack" onClick={toggleMobileMenu}></div>
            <div className="customNavBar">
                <span>
                    <button onClick={toggleMobileMenu}>
                        <FontAwesomeIcon icon={faClose} />
                    </button>
                </span>
                <div>
                    {
                        !checkRole(claims, UserRoles.Admin) &&
                        <IconMenuLink classLink={location.pathname === '/jobs' ? 'nav-link active' : 'nav-link'} classImage="icon" whereTo="/jobs" translation="menuJobs" icon={jobsIcon} iconAlt="Jobs Icon" />
                    }
                    <IconMenuLink classLink={location.pathname === '/chat' ? 'nav-link active' : 'nav-link'} classImage="icon" whereTo="/chat" translation="menuChat" icon={chatIcon} iconAlt="Chat Icon" additionalKey={unreadMessageCount} />
                    <Authorized role={UserRoles.Recrewter} authorized={<IconMenuLink classLink={location.pathname === '/dashboard' || location.pathname === '/personal' ? 'nav-link active' : 'nav-link'} classImage="icon" whereTo="/personal" translation="menuStaff" icon={staffIcon} iconAlt="Staff Icon" />} />
                </div>
                <div>
                    {/* this whole block is needed because a tooltip is shown on hover and we need the OverlayTrigger and Button for that */}
                    {

                        <div className="element">
                            <OverlayTrigger
                                placement="left"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip id="button-tooltip"><>{t("comingSoon")}</></Tooltip>}
                            >
                                <Button className="d-inline-flex align-items-center" variant={"outline-light"} style={{ padding: '0px' }}>
                                    <IconMenuLink classLink="nav-link" classImage="icon"
                                        whereTo={""}
                                        icon={premiumIcon}
                                        iconAlt="Premium Icon" />
                                </Button>
                            </OverlayTrigger>
                        </div>

                    }

                    <IconMenuLink classLink="nav-link" classImage="icon"
                        whereTo={"/chat/" + adminId + "/" + t("message.support.hello") + t("message.support.concern")}
                        icon={supportIcon}
                        iconAlt="Support Icon" />
                    <IconMenuLink classLink="nav-link" classImage="icon" whereTo="/settings" translation="" icon={settingsIcon} iconAlt="Settings Icon" />
                    <LanguageSwitch class="languageSwitch" />
                    {
                        isMobile ?
                            <div className="logoutIcon" onClick={() => logout()}>
                                <IconMenuLink classLink="nav-link logout-link" classImage="icon" whereTo="/" translation="menuLogout" icon={logoutIcon} iconAlt="Logout Icon" />
                            </div>
                            : null
                    }
                </div>
            </div>
        </div>
    )
}