import React, { useEffect, useMemo, useState } from "react";
import { ReactElement } from "react";
import { UserRoles } from "../config/Types/GeneralEnumDefinitions";
import { checkUserAuthorized, checkRoles, checkLoggedInStatus } from "../core/claimFunctions";
import { isCompanyProfileCompletedAPI } from "../core/apiFunctions";
import { useNavigate } from "react-router-dom";
import { getClaims } from "../core/handleJWT";

export default function AuthorizedExtended(props: authorizedProps) {
    const [isAuthorized, setIsAuthorized] = useState<boolean | null>(null);
    const [needsToLogIn, setNeedsToLogIn] = useState<boolean | null>(null);
    const [isProfileComplete, setIsProfileComplete] = useState<boolean | null>(null);
    const claims = useMemo(() => getClaims(), []);

    const navigate = useNavigate();

    useEffect(() => {
        const checkProfileCompletion = async () => {
            const updatedClaims = getClaims();
            const isloggedIn = checkLoggedInStatus(updatedClaims);

            if (isloggedIn) {
                setNeedsToLogIn(false);

                const isRoleCorrect = checkRoles(updatedClaims, props.roles);
                const isUserAuthorized = checkUserAuthorized(updatedClaims);

                if (isRoleCorrect && isUserAuthorized) {
                    try {
                        let companyProfileComplete = true;
                        if (checkRoles(updatedClaims, [UserRoles.Recrewter])) {
                            companyProfileComplete = (await isCompanyProfileCompletedAPI()).data;
                            if (companyProfileComplete === false) {
                                navigate('/settings');
                            }
                        }

                        setIsProfileComplete(companyProfileComplete);
                        setIsAuthorized(isUserAuthorized && companyProfileComplete);
                    } catch (error) {
                        setIsAuthorized(false);
                    }
                } else {
                    setIsAuthorized(false);
                    setIsProfileComplete(false);
                    if (!isUserAuthorized) {
                        navigate('/settings');
                    }
                }
            } else {
                setNeedsToLogIn(true);
                setIsAuthorized(false);
                setIsProfileComplete(false);
            }
        };

        checkProfileCompletion();
    }, [claims, navigate]);

    if (isAuthorized === null || isProfileComplete === null || needsToLogIn === null) {
        return null;
    }

    return (
        <>
            {isAuthorized && isProfileComplete && !needsToLogIn ?
                props.authorized : (needsToLogIn ? props.relogIn : props.notAuthorized)
            }
        </>
    )
}

interface authorizedProps {
    authorized: ReactElement;
    notAuthorized: ReactElement;
    relogIn?: ReactElement;
    roles: UserRoles[];
}