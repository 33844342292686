import { faDownload, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { FileType } from "../config/Types/GeneralEnumDefinitions";
import { downloadFileAPI, submitFileAPI } from "../core/apiFunctions";
import { downloadFile } from "../core/helperFunctions";

export default function FileUploadField(props: fileUploadFieldProps) {
    const {
        accept = "*",
        directUpload = true,
        field,
        fileName: initialFileName,
        filePath: initialFilePath,
        type,
        onChange,
        onRemove,
        onNewSource,
        onDownload,
        onUploadDone,
        onError,
        validationMessage,
        uploadTigger,
    } = props;

    const [filePath, setFilePath] = React.useState<string>(initialFilePath);
    const [fileName, setFileName] = React.useState<string>(initialFileName);

    useEffect(() => {
        setFilePath(initialFilePath);
        setFileName(initialFileName);
    }, [initialFilePath, initialFileName]);

    useEffect(() => {
        if (uploadTigger) {
            manualUpload();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadTigger]);


    const manualUpload = () => {
        const response = fetch(filePath);
        const blobPromise = response.then(r => r.blob());
        blobPromise.then(blob => {
            const file = new File([blob], fileName);
            const event = { target: { files: [file] } };
            upload(event as any);
        });
    }

    const upload = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const dto = {
                file: event.target.files[0],
                type: type
            };

            submitFileAPI(dto)
                .then((response) => {
                    const event = { targets: [{ value: dto.file.name, name: field }, { value: response.data, name: "filePath" }] };
                    onChange(event);

                    if (onUploadDone) {
                        onUploadDone(response.data, dto.file.name);
                    }

                    setFilePath(response.data);
                    setFileName(dto.file.name);
                })
                .catch((error) => {
                    if (onError) {
                        if (error.response.data[0].includes("body too large")) {
                            onError("error.fileTooLarge");
                        } else {
                            onError(error.response.data);
                        }
                    }
                });
        }
    }

    const remove = () => {
        const event = { targets: [{ value: '', name: field }, { value: '', name: "filePath" }] };
        onChange(event);
        if (onRemove) {
            onRemove();
        }

        setFilePath('');
        setFileName('');
    };

    const download = () => {
        if (onDownload) {
            onDownload();
        } else {
            downloadFileAPI(filePath).then((response) => {
                // create file link in browser's memory
                const href = URL.createObjectURL(response.data);
                downloadFile(href, fileName);
            })
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (directUpload) {
            upload(event);
        } else {
            const file = event.target.files?.[0];
            if (file) {
                const url = URL.createObjectURL(file);
                setFileName(file.name);
                setFilePath(url);

                if (onNewSource) {
                    onNewSource(url, file.name);
                }
            }
        }
    }

    return (
        <>
            {fileName ?
                <div className="file-upload-with-value" style={{border: "1px solid #cccccc", borderRadius: "5px"}}>
                    <label className="file-name">{fileName}</label>

                    <button className="btn btn-sm" onClick={remove}><i className="fas fa-trash-alt"></i>
                        <FontAwesomeIcon icon={faTrash} />
                    </button>

                    <button className="btn btn-sm" onClick={download}><i className="fas fa-download"></i>
                        <FontAwesomeIcon icon={faDownload} />
                    </button>
                </div>
                :
                <div className="file-upload-field mb-3" style={{border: "1px solid #cccccc", borderRadius: "5px"}}>
                    <input
                        className="form-control"
                        type="file"
                        id={field}
                        name={field}
                        onChange={handleChange}
                        accept={accept}
                    />
                </div>
            }
            {validationMessage && <label className="text-danger">{validationMessage}</label>}
        </>
    )
}

interface fileUploadFieldProps {
    // defines which field is updated by this component e.g. "imagePath", "videoPath", "filePath"
    field: string;

    fileName: string;
    filePath: string;

    // defines which type of file is uploaded
    type: FileType;

    // TODO: implement
    displayName?: string;

    onChange(event: any): void;
    onRemove?(): void;
    onNewSource?(source: string, fileName: string): void;
    onDownload?(): void;
    onUploadDone?(filePath: string, fileName: string): void;
    onError?(error: any): void;

    validationMessage?: string;

    // defines which file types can be uploaded
    accept?: string;

    // if true, file will be uploaded directly after selection
    directUpload?: boolean;

    // is going to be set by parent component to trigger upload
    uploadTigger?: boolean;
}
