import { Col, Nav, Row, Tab } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { getNameIdentifier, getRoleId } from "../../core/claimFunctions";
import {
    deleteUserAPI,
    getUserDetailsAPI,
    isCompanyProfileCompletedAPI,
    isProfileCompletedAPI,
    submitUserDetailsAPI,
    toggleNotificationsAPI,
} from "../../core/apiFunctions";
import AboutMe from "./cv/AboutMe";
import AuthenticationContext from "../../auth/AuthenticationContext";
import Authorized from "../../auth/Authorized";
import Certificate from "./cv/Certificate";
import CompanyDetails from "./CompanyDetails";
import DreamJob from "./DreamJob";
import Educations from "./cv/Educations";
import EmployeeManagement from "./admin/EmployeeManagement";
import LanguageSkills from "./cv/LanguageSkills";
import Menu from "../Menu/Menu";
import ModalContent from "../../utils/ModalContent";
import RecrewterManagement from "./admin/RecrewterManagement";
import Skills from "./cv/Skills";
import UserDetails from "./UserDetails";
import { UserRoles } from "../../config/Types/GeneralEnumDefinitions";
import WorkExperience from "./cv/WorkExperience";
import { useTranslation } from "react-i18next";
import { logout } from "../../core/handleJWT";
import PasswordChangeContent from "./PasswordChangeContent";
import NotificationContent from "./NotificationContent";
import StatisticsManagement from "./admin/StatisticsManagement";
import ComingSoon from "../../utils/CommingSoon";
import "./FullUserPage.scss";

export default function FullUserPage() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { claims } = useContext(AuthenticationContext);

    const showModalWindowVisibility = "ignoreModalWindowVisibility";
    const congratsModalShownKey = "congratsModalShown_" + getNameIdentifier(claims);
    const [ignoreMissingDataModal, setIgnoreMissingDataModal] = useState(localStorage.getItem(showModalWindowVisibility) === "true");
    const [notificationEnabled, setNotificationEnabled] = useState(true);
    const [showDeleteMyAccountModal, setShowDeleteMyAccountModal] = useState(false);
    const [showNotificationModal, setShowNotificationModal] = useState(false);
    const [showPasswordResetModal, setShowPasswordResetModal] = useState(false);
    const [isProfileVisible, setIsProfileVisible] = useState(true);
    const [missingData, setMissingData] = useState(false);
    const [profileCompleted, setProfileCompleted] = useState(false);
    const [companyProfileCompleted, setCompanyProfileCompleted] = useState(false);
    const [showCongratsModal, setShowCongratsModal] = useState(false);
    const [showCompleteOtherProfileModal, setShowCompleteOtherProfileModal] = useState(false);
    const [activeTab, setActiveTab] = useState("profile");
    const [role] = useState<UserRoles>(getRoleId(claims) ?? 0);

    const userId = getNameIdentifier(claims);

    useEffect(() => {
        getUserDetailsAPI().then((response) => {
            setNotificationEnabled(response.data.notificationsEnabled);
            checkProfileCompletionStatus();
        });
    }, []);

    const checkProfileCompletionStatus = async () => {
        const profileCompleteResponse = await isProfileCompletedAPI();
        setProfileCompleted(profileCompleteResponse.data);

        if (role === UserRoles.Recrewter) {
            const companyProfileCompleteResponse = await isCompanyProfileCompletedAPI();
            setCompanyProfileCompleted(companyProfileCompleteResponse.data);
        }

        const missing = !profileCompleted || (!companyProfileCompleted && role === UserRoles.Recrewter);
        setMissingData(missing);
    };

    function ignoreModalWindow(ignore: boolean) {
        setIgnoreMissingDataModal(ignore);
        localStorage.setItem(showModalWindowVisibility, ignore ? "true" : "false");
    }

    async function hideUser() {
        const response = await getUserDetailsAPI();
        response.data.isPublic = false;
        await submitUserDetailsAPI(response.data);
        setIsProfileVisible(false);
    }

    const handleUserProfileCompleted = () => {
        setProfileCompleted(true);

        if (role === UserRoles.Recrewter) {
            if (companyProfileCompleted) {
                showCongratsAndClearMissingData();
            } else {
                // Switch to company profile tab if personal profile is completed but company profile is not
                setActiveTab("company");
                setShowCompleteOtherProfileModal(true); // Show modal to complete company profile
            }
        } else if (role === UserRoles.Employee) {
            // Show congrats when employee completes their profile
            showCongratsAndClearMissingData();
        }
    };

    const handleCompanyProfileCompleted = () => {
        setCompanyProfileCompleted(true);

        if (role === UserRoles.Recrewter) {
            if (profileCompleted) {
                showCongratsAndClearMissingData();
            } else {
                // Switch to personal profile tab if company profile is completed but personal profile is not
                setActiveTab("profile");
                setShowCompleteOtherProfileModal(true); // Show modal to complete personal profile
            }
        }
    };

    const showCongratsAndClearMissingData = () => {
        if (!localStorage.getItem(congratsModalShownKey)) {
            setShowCongratsModal(true);
            localStorage.setItem(congratsModalShownKey, "true");
        }
        setMissingData(false);
    };

    const handleTabSelect = (key: any) => {
        setActiveTab(key);
    };

    return (
        <>
            <Menu />
            {missingData ? (
                // Check for missing data based on role and show appropriate modal
                role === UserRoles.Employee ? (
                    !profileCompleted && (
                        <ModalContent
                            size="lg"
                            show={missingData}
                            children={<>{t("noPageChangeMissingDataProfile")}</>}
                            onHideCallbackSecondary={() => setMissingData(false)}
                            onHideCallbackPrimary={() => setMissingData(false)}
                            title={"missingData"}
                            primaryButton={"ok"}
                        />
                    )
                ) : role === UserRoles.Recrewter ? (
                    !profileCompleted && !companyProfileCompleted ? (
                        <ModalContent
                            size="lg"
                            show={missingData}
                            children={<>{t("noPageChangeMissingDataProfileAndCompanyProfile")}</>}
                            onHideCallbackSecondary={() => setMissingData(false)}
                            onHideCallbackPrimary={() => setMissingData(false)}
                            title={"missingData"}
                            primaryButton={"ok"}
                        />
                    ) : !profileCompleted ? (
                        <ModalContent
                            size="lg"
                            show={missingData}
                            children={<>{t("justProfileLeft")}</>}
                            onHideCallbackSecondary={() => setMissingData(false)}
                            onHideCallbackPrimary={() => setMissingData(false)}
                            title={"missingData"}
                            primaryButton={"ok"}
                        />
                    ) : !companyProfileCompleted ? (
                        <ModalContent
                            size="lg"
                            show={missingData}
                            children={<>{t("justCompanyProfileLeft")}</>}
                            onHideCallbackSecondary={() => setMissingData(false)}
                            onHideCallbackPrimary={() => setMissingData(false)}
                            title={"missingData"}
                            primaryButton={"ok"}
                        />
                    ) : null
                ) : null
            ) : (
                !isProfileVisible && !ignoreMissingDataModal && (
                    <ModalContent
                        size="lg"
                        show={!ignoreMissingDataModal}
                        children={<>{t("reminder.setVisible")}</>}
                        onHideCallbackSecondary={() => ignoreModalWindow(true)}
                        title={"visibility"}
                        primaryButton={t("ok")}
                        secondaryButton={t("dontShowAgain")}
                    />
                )
            )}

            {showCongratsModal && (
                <ModalContent
                    size="lg"
                    show={showCongratsModal}
                    children={<>{t("congratulationsProfileCompleted")}</>}
                    onHideCallbackPrimary={() => setShowCongratsModal(false)}
                    title={t("congratulations")}
                    primaryButton={t("ok")}
                />
            )}

            {showCompleteOtherProfileModal && (
                <ModalContent
                    size="lg"
                    show={showCompleteOtherProfileModal}
                    children={<>{activeTab === "profile" ? t("justProfileLeft") : t("justCompanyProfileLeft")}</>}
                    onHideCallbackPrimary={() => setShowCompleteOtherProfileModal(false)}
                    title={t("missingData")}
                    primaryButton={"ok"}
                />
            )}

            {showDeleteMyAccountModal && (
                <ModalContent
                    size="lg"
                    show={showDeleteMyAccountModal}
                    children={
                        role === UserRoles.Employee
                            ? t("deleteMyAccountWarningEmployee")
                            : t("deleteMyAccountWarning")
                    }
                    onHideCallbackSecondary={() => setShowDeleteMyAccountModal(false)}
                    onHideCallbackPrimary={() => deleteUserAPI().then(() => logout())}
                    onHideCallbackThird={role === UserRoles.Employee ? () => hideUser() : undefined}
                    onClose={() => setShowDeleteMyAccountModal(false)}
                    title={"deleteMyAccount"}
                    primaryButton={t("delete")}
                    secondaryButton={t("cancel")}
                    thirdButton={role === UserRoles.Employee ? t("hide") : undefined}
                />
            )}

            {showPasswordResetModal && (
                <ModalContent
                    onClose={() => setShowPasswordResetModal(false)}
                    size="lg"
                    show={showPasswordResetModal}
                    children={<PasswordChangeContent handleCloseModal={() => setShowPasswordResetModal(false)} />}
                    title={t("resetPassword")}
                />
            )}

            {showNotificationModal && (
                <ModalContent
                    size="sm"
                    show={showNotificationModal}
                    onClose={() => setShowNotificationModal(false)}
                    children={
                        <NotificationContent
                            setNotification={(enabled) => setNotificationEnabled(enabled)}
                            toggleNotificationsAPI={toggleNotificationsAPI}
                            isNotificationEnabled={notificationEnabled}
                        />
                    }
                    title={t("notifications")}
                />
            )}

            <div className="container-fluid settings">
                <Tab.Container activeKey={activeTab} onSelect={handleTabSelect} defaultActiveKey="profile">
                    <Row className="p-5">
                        <Col md={3}>
                            <Nav variant="pills" className="flex-column">
                                <Nav.Item>
                                    <Nav.Link eventKey="profile">{t("userProfile")}</Nav.Link>
                                </Nav.Item>
                                {role === UserRoles.Employee && (
                                    <>
                                        <Nav.Item>
                                            <Nav.Link eventKey="dreamJob">{t("myDreamJob")}</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="cv">{t("myCv")}</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link onClick={() => (userId !== "" ? navigate("/cv/" + userId) : navigate("/settings"))}>
                                                {t("previewProfile")}
                                            </Nav.Link>
                                        </Nav.Item>
                                    </>
                                )}
                                {role === UserRoles.Recrewter && (
                                    <>
                                        <Nav.Item>
                                            <Nav.Link eventKey="company">{t("companyProfile")}</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link onClick={() => (userId !== "" ? navigate("/cv/" + userId) : navigate("/settings"))}>
                                                {t("previewProfile")}
                                            </Nav.Link>
                                        </Nav.Item>
                                    </>
                                )}
                                {role === UserRoles.Admin && (
                                    <>
                                        <Nav.Item>
                                            <Nav.Link eventKey="admin-recrewters">{t("companyAndRecrewter")}</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="admin-employees">{t("employeeTitle")}</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="admin-statistics">{t("statistics")}</Nav.Link>
                                        </Nav.Item>
                                    </>
                                )}
                                <Nav.Item>
                                    <Nav.Link onClick={() => setShowNotificationModal(true)}>{t("notifications")}</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link onClick={() => setShowPasswordResetModal(true)}>{t("changePassword")}</Nav.Link>
                                </Nav.Item>
                                {role !== UserRoles.Admin && (
                                    <Nav.Item>
                                        <Nav.Link onClick={() => setShowDeleteMyAccountModal(true)}>{t("deleteMyAccount")}</Nav.Link>
                                    </Nav.Item>
                                )}
                            </Nav>
                        </Col>
                        <Col md={9}>
                            <Tab.Content>
                                <Tab.Pane eventKey="profile">
                                    <UserDetails isProfileVisible={isProfileVisible} onUserProfileCompleted={handleUserProfileCompleted} />
                                </Tab.Pane>
                                {
                                    role === UserRoles.Recrewter &&
                                    <Tab.Pane eventKey="company">
                                        <CompanyDetails onCompanyProfileCompleted={handleCompanyProfileCompleted} />
                                    </Tab.Pane>
                                }
                                {
                                    role === UserRoles.Employee &&
                                    <Tab.Pane eventKey="cv">
                                        <Authorized role={UserRoles.Employee} authorized={<AboutMe />} />
                                        <Authorized role={UserRoles.Employee} authorized={<LanguageSkills />} />
                                        <Authorized role={UserRoles.Employee} authorized={<Skills />} />
                                        <Authorized role={UserRoles.Employee} authorized={<WorkExperience />} />
                                        <Authorized role={UserRoles.Employee} authorized={<Educations />} />
                                        <Authorized role={UserRoles.Employee} authorized={<Certificate />} />
                                    </Tab.Pane>
                                }
                                {
                                    role === UserRoles.Employee &&
                                    <Tab.Pane eventKey="dreamJob">
                                        <Authorized role={UserRoles.Employee} authorized={<DreamJob />} />
                                    </Tab.Pane>
                                }
                                {
                                    role === UserRoles.Admin &&
                                    <Tab.Pane eventKey="admin-recrewters">
                                        <Authorized role={UserRoles.Admin} authorized={<RecrewterManagement />} />
                                    </Tab.Pane>
                                }
                                {
                                    role === UserRoles.Admin &&
                                    <Tab.Pane eventKey="admin-employees">
                                        <Authorized role={UserRoles.Admin} authorized={<EmployeeManagement />} />
                                    </Tab.Pane>
                                }
                                {
                                    role === UserRoles.Admin &&
                                    <Tab.Pane eventKey="admin-statistics">
                                        <Authorized role={UserRoles.Admin} authorized={<StatisticsManagement />} />
                                        <ComingSoon title={""} />
                                    </Tab.Pane>
                                }
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </div>
        </>
    );
}