import React, { useContext, useEffect, useState } from "react";
import IconMenuLink from "../utils/IconMenuLink";
import logoutIcon from "../img/icon/logout.png";
import { NavDropdown } from "react-bootstrap";
import { logout } from "../core/handleJWT";
import { getCompanyNameForRecrewterAPI } from "../core/apiFunctions";
import AuthenticationContext from "../auth/AuthenticationContext";
import { checkRole, getEmail, getLicence, getName, getRoleId } from "../core/claimFunctions";
import { ImageType, UserRoles } from "../config/Types/GeneralEnumDefinitions";
import "./LoggedInUser.scss";
import { useTranslation } from "react-i18next";
import { useProfileImage } from "../contexts/ImageContext";

export default function LoggedInUser() {
    const { claims } = useContext(AuthenticationContext);
    const { images } = useProfileImage();
    const [companyName, setCompanyName] = useState<string>();

    const companyNameKey = "company-name";

    useEffect(() => {
        getAndSetCompanyName();
    }, [claims]);

    const setMailAsCompanyName = () => {
        const mail = getEmail(claims);
        setCompanyName(mail);
        localStorage.setItem(companyNameKey, mail);
    };

    const getAndSetCompanyName = () => {
        if (checkRole(claims, UserRoles.Recrewter)) {
            getCompanyNameForRecrewterAPI().then((response) => {
                if (response.data === "") {
                    setMailAsCompanyName();
                } else {
                    setCompanyName(response.data);
                    localStorage.setItem(companyNameKey, response.data);
                }
            });
        } else {
            setMailAsCompanyName();
        }
        window.addEventListener(companyNameKey, () => {
            setCompanyName(localStorage.getItem(companyNameKey)!);
        });
    };

    const { t } = useTranslation();

    return (
        <>
            <div className="infoLoggedInUser">
                <strong>
                    <p className="nameCompany">
                        {companyName}
                    </p>
                </strong>
                <p className="nameRole">
                    {
                        checkRole(claims, UserRoles.Admin)
                            ? t("administrator")
                            : checkRole(claims, UserRoles.Recrewter)
                                ? t("recruiter")
                                : checkRole(claims, UserRoles.Employee)
                                    ? t("employee")
                                    : ''
                    }
                </p>
                <p className="nameUser">{getName(claims)}</p>
                <p className="versionText">{getLicence(claims)}</p>
            </div>
            <NavDropdown title={
                <div className="iconLoggedInUser">
                    <img src={images[ImageType.ProfileImage]} className="profilImage" alt="avatar" />
                </div>
            } id="collasible-nav-dropdown" align="end">
                <NavDropdown.Item onClick={() => {
                    logout();
                }}>
                    <IconMenuLink classLink="nav-link logout-link" classImage="icon" whereTo="/" translation="menuLogout" icon={logoutIcon} iconAlt="Logout Icon" />
                </NavDropdown.Item>
            </NavDropdown>
        </>
    );
}
