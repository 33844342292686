import React, { useEffect, useState } from "react";
import { aboutMeDTO } from "../../../core/dto/user.models";
import Button from "../../../utils/Button";
import { downloadFileAPI, getAboutMeAPI, submitAboutMeAPI } from "../../../core/apiFunctions";
import TextField from "../../../utils/TextField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faUndo } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { Col, Row } from "react-bootstrap";
import FileUploadField from "../../../utils/FileUploadField";
import { FileType } from "../../../config/Types/GeneralEnumDefinitions";
import { downloadFile } from "../../../core/helperFunctions";
import DisplayErrors from "../../../utils/DisplayErrors";
import useFeedback from "../../../utils/useFeedback";
export default function AboutMe() {

    const [errors, setErrors] = useState<string[]>([]);

    const [aboutMeInital, setAboutMeInitial] = useState<aboutMeDTO>();
    const [aboutMe, setAboutMe] = useState<aboutMeDTO>();

    const [videoSource, setVideoSource] = useState<string | undefined>(undefined);

    const [uplaodTigger, setUploadTigger] = useState<boolean>(false);
    const [videoHasChanged, setVideoHasChanged] = useState<boolean>(false);

    const [isEditing, setIsEditing] = useState<boolean>(false);

    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const { showSuccess }  = useFeedback();

    // checks if the about me data has changed and sets the isEditing flag
    useEffect(() => {
        if (aboutMe && aboutMeInital && aboutMeInital !== aboutMe) {
            setIsEditing(true);
        } else {
            setIsEditing(false);
        }
    }, [aboutMe, aboutMeInital]);

    // is called when the component is loaded
    // gets the about me data from the server and loads the video if it exists
    useEffect(() => {
        getAboutMeAPI().then(response => {
            setAboutMeInitial(response.data);
            setAboutMe(response.data);

            // Download video if it exists
            if (response.data.filePath && response.data.filePath !== "..") {
                setIsLoading(true);
                downloadFileAPI(response.data.filePath).then((response) => {
                    // Create local object URL from blob
                    const url = URL.createObjectURL(response.data);
                    // Set video source to local object URL
                    setVideoSource(url);
                    setIsLoading(false);
                });
            }
        }).finally(() => {
            setIsLoading(false);
        }).catch(error => {
            setErrors([error]);
        });
    }, []);

    // is called when the text fields are changed
    const handleOnChange = (e: any) => {
        const { name, value } = e.target;
        setAboutMe({ ...aboutMe!, [name]: value });
    }

    // is called when the file upload component has a new file with fileName and filePath
    const handleOnChangeForFile = (e: any) => {
        e.targets.forEach((target: { name: any; value: any; }) => {
            const { name, value } = target;
            setAboutMe({ ...aboutMe!, [name]: value });
        });
    }

    // is called when the file upload component has a new source
    const handleOnNewSource = (source: string, fileName: string) => {
        setVideoSource(source);
        setAboutMe({ ...aboutMe!, filePath: source, videoName: fileName });
        setVideoHasChanged(true);
        setIsEditing(true);
    }

    // is called when the file upload component has an error
    const handleOnError = (error: string) => {
        setErrors([error]);
        setIsUploading(false);
    }

    // download the current video from local browser
    const handleOnDownload = () => {
        if (videoSource && videoSource.startsWith("blob")) {
            downloadFile(videoSource, aboutMe!.videoName);
        }

        // TODO: After download, site reloads. Unwanted behaviour.
    }

    // is called when the file upload component has finished uploading the file to the server
    const handleUploadDone = (filePath: string, fileName: string) => {
        const newAboutMe = { ...aboutMe!, filePath: filePath, videoName: fileName };
        submitAboutMeAPI(newAboutMe);
        setAboutMeInitial(newAboutMe);
        setAboutMe(newAboutMe);
        setUploadTigger(false);
        setIsEditing(false);
        setIsUploading(false);
    }

    // remove the current video
    const handleOnRemove = () => {
        setVideoSource(undefined);
        setAboutMe({ ...aboutMe!, filePath: '', videoName: '' });
    }

    // reset the form to the original values
    const setOrigValues = () => {
        setAboutMe(aboutMeInital);
        setVideoSource(aboutMeInital?.filePath);
        setErrors([]);
        setIsEditing(false);
    }

    // submit the form
    const submit = async () => {
        // upload video if video is local
        if (videoHasChanged && videoSource && videoSource.startsWith("blob")) {
            // Check if file size is greater 250MB
            const video = await fetch(videoSource);
            const videoBlob = await video.blob();
            if (videoBlob.size > 250000000) {
                setErrors(["error.fileTooLarge250"]);
                return;
            }

            // Sets the trigger to true so the file upload component will upload the file
            setIsUploading(true);
            setUploadTigger(true);
        } else {
            submitAboutMeAPI(aboutMe!);
            setAboutMeInitial(aboutMe);
            setIsEditing(false);
        }
        showSuccess(t("successfullySaved"));
    }

    return (
        <div className="card mt-3">
            <div className="card-header header-background">
                <Row>
                    <Col md={12}>
                        <h5 className="card-title">{t("aboutMe")}</h5>
                    </Col>
                </Row>
            </div>

            <div className="card-body">
                <Row>
                    <Col md={6}>
                        <TextField field="aboutMe" value={aboutMe?.aboutMe} as="textarea"
                            formFloating={false} onChange={handleOnChange} placeholder={t("aboutMePlaceholder")}
                            height="325px" />
                    </Col>
                    <Col md={6}>

                        {isLoading ?
                            <div className="spinner-border" role="status" />
                            :
                            videoSource ?
                                <video className="VideoInput_video mb-2" width="100%" height="250px" controls src={videoSource} />
                                :
                                <p>{t("noVideo")}</p>
                        }
                        <FileUploadField type={FileType.AboutMeVideo} filePath={aboutMe?.filePath!} fileName={aboutMe?.videoName!}
                            onRemove={handleOnRemove} onNewSource={handleOnNewSource} directUpload={false}
                            onDownload={handleOnDownload} uploadTigger={uplaodTigger} field="videoName" onError={handleOnError}
                            onChange={handleOnChangeForFile} onUploadDone={handleUploadDone} accept="video/*" />
                    </Col>
                </Row>

                <Row>
                    <Col md={12}>
                        <Button class="btn btn-primary" hidden={!isEditing} type="button" onClick={submit} disabled={isUploading}>
                            {isUploading ?
                                <div className="spinner-border spinner-border-sm" role="status" />
                                :
                                <FontAwesomeIcon icon={faSave} />
                            } {t("save")}
                        </Button>



                        <Button class="btn btn-danger ms-3" hidden={!isEditing} type="button" onClick={setOrigValues}>
                            <FontAwesomeIcon icon={faUndo} /> {t("cancel")}
                        </Button>
                    </Col>
                </Row>

                <DisplayErrors errors={errors} />
            </div>
        </div>
    )
}