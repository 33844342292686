import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Accordion, Button, Card } from "react-bootstrap";
import UserEntry from "../../../utils/UserEntry";
import { activateUserAPI, deleteUserAPI, getCompaniesForAdminAPI, getCompanyLogoAPI, getRecrewtersForAdminAPI } from "../../../core/apiFunctions";
import { recrewterDetailsForAdminDTO } from "../../../core/dto/admin.models";
import "./Admin.scss";
import { CgWebsite, } from "react-icons/cg";
import { AiOutlinePhone, AiOutlineMail, AiFillLinkedin, AiFillHome } from "react-icons/ai";
import { FaXingSquare } from "react-icons/fa";
import { convertDateTimeToDate } from "../../../core/helperFunctions";
import { companyDetailsDTO } from "../../../core/dto/user.models";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { BsCalendarDate } from "react-icons/bs";
import * as XLSX from "xlsx";
import useFeedback from "../../../utils/useFeedback";
import { Country, State } from "../../../config/Types/PlaceEnumDefinitions";
import { enumToString } from "../../../core/helperConst";
import { Title } from "../../../config/Types/GeneralEnumDefinitions";

export default function RecrewterManagement() {

    const [recrewters, setRecrewters] = React.useState<recrewterDetailsForAdminDTO[]>([]);
    const [companies, setCompanies] = React.useState<companyDetailsDTO[]>([]);
    const [stats, setStats] = React.useState({ activeRecrewters: 0, inactiveRecrewters: 0 });
    const { showSuccess, showError }  = useFeedback();

    const { t } = useTranslation();

    function updateStats(recrewters: recrewterDetailsForAdminDTO[]) {
        const activeRecrewters = recrewters.filter(e => e.isActive).length;
        const inactiveRecrewters = recrewters.length - activeRecrewters;
        setStats({ activeRecrewters, inactiveRecrewters });
    }

    useEffect(() => {
        getRecrewtersForAdminAPI().then((response) => {
            setRecrewters(response.data);
        });

        loadCompanies();

    }, []);

    useEffect(() => {
        updateStats(recrewters);
    }, [recrewters]);

    const loadCompanies = async () => {
        await getCompaniesForAdminAPI().then(async (response) => {
            const companiesWithLogos = [];

            for (const company of response.data) {
                try {
                    const logoResponse = await getCompanyLogoAPI(company.id);
                    if (logoResponse.status !== 200) continue;
                    const logoURL = URL.createObjectURL(logoResponse.data);
                    companiesWithLogos.push({ ...company, logo: logoURL, });
                }
                catch(error) {
                    continue;
                }
            }

            setCompanies(companiesWithLogos);
        });
    };

    const exportToExcel = async () => {
        try {
            const workbook = XLSX.utils.book_new();

            // Export Companies
            const sortedCompanies = [...companies].sort((a, b) =>
                a.name.localeCompare(b.name)
            );
            const worksheetCompanies = XLSX.utils.json_to_sheet(
                sortedCompanies.map((company) => ({
                    createdDate: company.createdDate,
                    name: company.name,
                    address: company.address,
                    country: enumToString(Country, company.country),
                    state: enumToString(State, company.state),
                    city: company.city,
                    postcode: company.postcode,
                    uid: company.uid,
                    email: company.email,
                    phoneNumber: company.phoneNumber,
                    linkedIn: company.linkedIn,
                    xing: company.xing,
                    website: company.website,
                    youTubeUrl: company.youTubeUrl,
                    description: company.description,
                }))
            );
            XLSX.utils.book_append_sheet(workbook, worksheetCompanies, "Companies");
    
            // Export Recrewters
            const sortedRecrewters = [...recrewters].sort((a, b) =>
                a.lastName.localeCompare(b.lastName)
            );
            const worksheetRecrewters = XLSX.utils.json_to_sheet(
                sortedRecrewters.map((recrewter) => ({
                    createdDate: recrewter.createdDate,
                    companyName: recrewter.companyName,
                    title: enumToString(Title, recrewter.title),
                    firstName: recrewter.firstName,
                    lastName: recrewter.lastName,
                    dateOfBirth: recrewter.dateOfBirth,
                    nationality: recrewter.nationality,
                    residencePermit: recrewter.residencePermit,
                    state: enumToString(State, recrewter.state),
                    email: recrewter.email,
                    phoneNumber: recrewter.phoneNumber,
                    mobileNumber: recrewter.mobileNumber,
                    linkedIn: recrewter.linkedIn,
                    xing: recrewter.xing,
                    website: recrewter.website,
                    isProfileComplete: recrewter.isProfileComplete,
                    isActive: recrewter.isActive,
                    isPublic: recrewter.isPublic,
                    notificationsEnabled: recrewter.notificationsEnabled,
                    isManager: recrewter.isManager,
                }))
            );
            XLSX.utils.book_append_sheet(workbook, worksheetRecrewters, "Recrewters");
    
            // Save the workbook as an Excel file
            XLSX.writeFile(workbook, "companies_recrewters.xlsx");
    
            // Show success message
            showSuccess(t("successfullySaved"));
        } catch (error) {
            // Show error message if an exception occurs during export
            showError(t("errorOccuredWhileExporting"));
        }
    };


    return (
        <>
            <div className="text-end mt-3">
                <Button className="btn btn-primary" onClick={exportToExcel}>
                    {t("exportToExcel")} 
                </Button>
            </div>
            <h5>Companies & Recrewters</h5>
            <div>
                <h6>Total Companies: {companies.length}</h6>
                <h6>Total Recrewters: {recrewters.length}</h6>
                <h6>Active Recrewters: {stats.activeRecrewters}</h6>
                <h6>Inactive Recrewters: {stats.inactiveRecrewters}</h6>
            </div>
            <Accordion className="admin">
                {companies.map((company) => (
                    <Card key={(company as companyDetailsDTO).id} className="mb-4 shadow bg-secondary rounded-3">
                        <Accordion.Item eventKey={company.id}>
                            {/* Needs to be implemented with backend */}
                            {/* <div className="companiesSwitch">
                            <Form.Check
                                type="switch"
                                id="custom-switch"
                                label="Is Active"
                            />
                            </div> */}
                            <Accordion.Header>
                                <div className="companiesListHeader">
                                    <div>
                                        <img src={company.logo} alt="company logo" className="companyLogo" />
                                    </div>
                                    <div className="companyName">
                                        {company.name}
                                    </div>
                                    <div className="companyDetails">
                                        <div><CgWebsite /> {company.website}</div>
                                        <div><AiOutlineMail /> {company.email}</div>
                                        <div><AiOutlinePhone /> {company.phoneNumber}</div>
                                        <div><BsCalendarDate /> {convertDateTimeToDate(company.createdDate)}</div>
                                    </div>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body style={{ padding: "0" }}>
                                <Accordion>
                                    <div className="companiesActionBtns">
                                        {/* <ButtonGroup>
                                        <Button variant="outline-danger"><FontAwesomeIcon icon={faTrash} /> Delete</Button>
                                        <Button variant="outline-success"><FontAwesomeIcon icon={faPen} /> Edit</Button>
                                        <Button variant="outline-primary"><FontAwesomeIcon icon={faSmile} /> Action</Button>
                                    </ButtonGroup> */}
                                    </div>
                                    <div className="companiesAndUserDetailsList">
                                        <div>
                                            <h6>Address</h6>
                                            <p>
                                                {company.address} <br />
                                                {company.postcode} {company.state} <br />
                                                {company.country}
                                            </p>
                                        </div>
                                        <div>
                                            <h6>Websites</h6>
                                            <p>
                                                {company.linkedIn && <> <AiFillLinkedin /> <Link to={company.linkedIn}>{company.linkedIn}</Link> <br /> </>}
                                                {company.website && <> <AiFillHome /> <Link to={company.website}>{company.website}</Link> <br /> </>}
                                                {company.xing && <> <FaXingSquare /> <Link to={company.xing}>{company.xing}</Link> <br /> </>}
                                            </p>
                                        </div>
                                        <div> {company.uid && <> <h6>UID</h6> <p>{company.uid}</p> </>} </div>
                                    </div>

                                    {recrewters
                                        .filter((recrewter) => recrewter.companyId === company.id)
                                        .map((recrewter) => (
                                            <Accordion.Item eventKey={recrewter.id}>
                                                <Accordion.Header>
                                                    <UserEntry user={recrewter} displayMail={true} displayPhone={true}
                                                        displayChat={true} displayActivationStatus={true} />
                                                </Accordion.Header>
                                                <Accordion.Body style={{ padding: "0" }}>
                                                    <div className="companiesAndUserDetailsList">
                                                        <div className="deleteUserBtn">
                                                            <Button
                                                                variant="outline-danger"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();

                                                                    // Display a confirmation dialog
                                                                    if (window.confirm('Are you sure you want to delete this user?')) {
                                                                        deleteUserAPI(recrewter.id);
                                                                        setRecrewters(recrewters.filter((u) => u.id !== recrewter.id));

                                                                        // if it is the last user of the company, delete the company
                                                                        if (recrewters.filter((u) => u.companyId === company.id).length === 1) {
                                                                            setCompanies(companies.filter((c) => c.id !== company.id));
                                                                        }
                                                                    }
                                                                }}
                                                            >
                                                                <FontAwesomeIcon icon={faTrash} /> Delete
                                                            </Button>
                                                        </div>

                                                        <div className="form-check form-switch public-toggle">
                                                            <input className="form-check-input mb-1" type="checkbox" id="flexSwitchCheckDefault"
                                                                checked={recrewter.isActive} onChange={(e) => {
                                                                    e.stopPropagation();
                                                                    activateUserAPI(recrewter.id, !recrewter.isActive);
                                                                    recrewter.isActive = !recrewter.isActive;
                                                                    setRecrewters([...recrewters]);
                                                                }} />
                                                            <label className="form-check-label ms-2" htmlFor="flexSwitchCheckDefault">
                                                                {recrewter.isActive ? t("Active") : t("Inactive")}
                                                            </label>
                                                        </div>
                                                        <div> {recrewter.dateOfBirth && <> <h6>Date of birth</h6> <p>{convertDateTimeToDate(recrewter.dateOfBirth)}</p> </>} </div>
                                                        <div> {recrewter.nationality && <> <h6>Nationality</h6> <p>{recrewter.nationality}</p> </>} </div>
                                                        <div> {recrewter.mobileNumber && <> <h6>Mobile number</h6> <p>{recrewter.mobileNumber}</p> </>} </div>
                                                        <div>
                                                            <h6>Websites</h6>
                                                            <p>
                                                                {recrewter.linkedIn && <> <AiFillLinkedin /> <Link to={recrewter.linkedIn}>{recrewter.linkedIn}</Link> <br /> </>}
                                                                {recrewter.website && <> <AiFillHome /> <Link to={recrewter.website}>{recrewter.website}</Link> <br /> </>}
                                                                {recrewter.xing && <> <FaXingSquare /> <Link to={recrewter.xing}>{recrewter.xing}</Link> <br /> </>}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        ))}
                                </Accordion>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Card>
                ))}
            </Accordion>
        </>
    );
}