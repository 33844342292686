import { useContext, useEffect, useState } from 'react';
import AuthenticationContext from './AuthenticationContext';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import React from 'react';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import YupPassword from 'yup-password';
import Button from '../utils/Button';
import DisplayErrors from '../utils/DisplayErrors';
import './AuthPage.scss';
import Mainmenu from '../components/Menu/Mainmenu';
import Header from '../components/Header/Header';
import { UserRoles } from '../config/Types/GeneralEnumDefinitions';
import { exchangeLoginTokenAPI, ssoRegisterAPI } from '../core/apiFunctions';
import { getClaims, saveToken } from '../core/handleJWT';
import { useTranslation } from 'react-i18next';
import { getRoleId } from '../core/claimFunctions';
YupPassword(yup);

export default function AuthSSOPage() {
    const navigate = useNavigate();
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get('email') || '';
    const ssoProvider = queryParams.get('provider') || '';
    const role = queryParams.get('role') || '';
    const errorsFromQuery = queryParams.get('error') || '';

    const [errors, setErrors] = useState<string[]>([]);
    const [choosenRole, setChoosenRole] = useState<UserRoles>();

    const { update } = useContext(AuthenticationContext);
    const { t } = useTranslation();

    useEffect(() => {
        if (errorsFromQuery) {
            navigate('/auth', { state: { error: errorsFromQuery } });
        }
    }, [errorsFromQuery, navigate]);

    useEffect(() => {
        async function handleAutoLogin() {
            if (ssoProvider && role !== 'none') {
                try {
                    const response = (await exchangeLoginTokenAPI()).data;
                    saveToken(response);
                    var claims = getClaims();
                    update(claims);
                    var userRole = getRoleId(claims);

                    if (userRole === UserRoles.Recrewter || userRole === UserRoles.Employee) {
                        navigate('/dashboard');
                    } else if (userRole === UserRoles.Admin) {
                        navigate('/settings');
                    } else {
                        navigate('/auth', { state: { error: t('invalidRole') } });
                    }
                } catch (error: any) {
                    navigate('/auth', { state: { error: error.response?.data || t('loginFailed') } });
                }
            }
        }

        handleAutoLogin();
    }, [ssoProvider, role, navigate, t]);


    async function submit() {
        try {
            setErrors([]);
            if (!choosenRole) {
                setErrors([t('missingRole')]);
                return;
            }

            if (ssoProvider) {
                await ssoRegisterAPI(choosenRole);
                const response = (await exchangeLoginTokenAPI()).data;
                saveToken(response);
                update(getClaims());
                navigate('/settings');
            }
        } catch (error: any) {
            setErrors([error.response?.data || t('registrationFailed')]);
        }
    }

    if (role !== 'none') {
        return null;
    }

    return (
        <>
            <Mainmenu />
            <Header title={t('register')} text="" imageUrl="doctors-walking-away.png" />
            <div className="authContainer">
                <Formik initialValues={{ email: email }} onSubmit={submit}>
                    <Form className="login">
                        <h3>{t('register')}</h3>
                        <h6>{t('registerAs')} {email}</h6>

                        <div className="chooseRoleContainer">
                            <div className="cc-selector">
                                <input
                                    id="recrewter"
                                    type="radio"
                                    name="choosenRole"
                                    value="recrewter"
                                    onChange={() => setChoosenRole(UserRoles.Recrewter)}
                                />
                                <label className="drinkcard-cc recrewter chooseRole" htmlFor="recrewter">
                                    {t('recrewter')}
                                </label>
                                <input
                                    id="employee"
                                    type="radio"
                                    name="choosenRole"
                                    value="employee"
                                    onChange={() => setChoosenRole(UserRoles.Employee)}
                                />
                                <label className="drinkcard-cc employee chooseRole" htmlFor="employee">
                                    {t('menuEmployee')}
                                </label>
                            </div>
                        </div>

                        <DisplayErrors errors={errors} />
                        <Button class="btn btn-primary me-3" type="submit">
                            {t('btnSend')}
                        </Button>
                        <Link className="btn btn-secondary" to="/">
                            {t('cancel')}
                        </Link>
                    </Form>
                </Formik>
            </div>
        </>
    );
}