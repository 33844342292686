import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaEnvelope, FaGlobe } from "react-icons/fa";
import { IoChatbubbleEllipsesOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import {
  Availability,
  EmploymentType,
  JobPosition,
  EducationLevel,
  Salary,
  Title,
  Workload,
} from "../../config/Types/GeneralEnumDefinitions";
import {
  getCompanyDetailsByJobOfferAPI,
  getCompanyLogoAPI,
  getDreamJobDetailsAPI,
  getJobDescriptionAPI,
  getProfilePhotoAPI,
  getRecrewterFromJobOfferAPI,
} from "../../core/apiFunctions";
import { JobType, MedicalDivision } from "../../config/Types/MedicalEnumDefinitions";
import { companyDetailsDTO, recrewterDTO } from "../../core/dto/user.models";
import { AlertAction, handleOnChange } from "../../core/helperConst";
import DropdownField from "../../utils/DropdownField";
import TextField from "../../utils/TextField";
import { State, Country } from "../../config/Types/PlaceEnumDefinitions";
import { internalJobDTO } from "../../core/dto/dto.models";
import youtubeLogo from "../../img/youtube.png";

export default function JobOfferCompanyDetails(
  props: JobOfferCompanyDetailsProperties
) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const newJobOffer = "create";
  const [youTubeLink, setyouTubeLink] = useState("");
  const [companyLogo, setCompanyLogo] = useState<string>();
  const [recrewterProfilePicture, setRecrewterProfilePicture] =
    useState<string>();
  const [companyDetails, setCompanyDetails] =
    React.useState<companyDetailsDTO>();
  const [recrewter, setRecrewter] = React.useState<recrewterDTO>();
  const [jobDropDowns, setJobDropDowns] = React.useState<internalJobDTO>();
  const [dataDropdown, setDataDropdown] = useState<internalJobDTO>(jobDropDowns as internalJobDTO);
  const [jobTitle, setJobTitle] = useState("");

  // Handle onChange for dropdowns
  const onChange = handleOnChange(jobDropDowns, setJobDropDowns);

  useEffect(() => {
    // Fetch the job offer details
    const fetchDescription = async () => {
      const jobDescription = await getJobDescriptionAPI(props.jobOfferId);

      setJobTitle(jobDescription.data.title);
    };
    fetchDescription();

    // To show which company created the job offer
    const fetchCompanyDetails = async () => {
      const companyDetails = await getCompanyDetailsByJobOfferAPI(
        props.jobOfferId
      );

      companyDetails.data.youTubeUrl = correctYouTubeLink(
        companyDetails.data.youTubeUrl
      );

      // Iframe can't hance the watch?v= in the link, so we need to replace it with embed/
      setyouTubeLink(companyDetails.data.youTubeUrl);
      setCompanyDetails(companyDetails.data);

      // To get the company logo
      getCompanyLogoAPI(companyDetails.data.id).then((response) => {
        const url = URL.createObjectURL(response.data);
        setCompanyLogo(url);
      });
    };
    fetchCompanyDetails();

    // To show which user created the job offer
    const fetchRecrewter = async () => {
      const jobOfferCreator = await getRecrewterFromJobOfferAPI(
        props.jobOfferId
      );
      // get image from user by id
      getProfilePhotoAPI(jobOfferCreator.data.userId).then((response) => {
        if(response && response.data !== null){
          const url = URL.createObjectURL(response.data);
          setRecrewterProfilePicture(url);
        }
      });
      setRecrewter(jobOfferCreator.data);
    };
    fetchRecrewter();

    // To show which job details are selected
    const fetchJobDropDowns = async () => {
      const dreamJobDetails = await getDreamJobDetailsAPI(props.jobOfferId);
      setJobDropDowns(dreamJobDetails.data);
    };
    fetchJobDropDowns();
  }, []);

  useEffect(() => {
    var dataDropdowns: internalJobDTO = {
        id: "",
        title: "",
        description: "",
        discriminator: "Internal",
        createdDate: undefined,
        lastUpdatedDate: undefined,
        companyUrl: youTubeLink,
        workload: jobDropDowns?.workload as Workload,
        salary: jobDropDowns?.salary as Salary,
        employmentType: jobDropDowns?.employmentType as EmploymentType,
        country: jobDropDowns?.country as Country,
        jobPosition: jobDropDowns?.jobPosition as JobPosition,
        jobType: jobDropDowns?.jobType as JobType,
        division: jobDropDowns?.division as MedicalDivision,
        availability: jobDropDowns?.availability as Availability,
        state: jobDropDowns?.state as State,
        educationLevel: jobDropDowns?.educationLevel as EducationLevel,
        isPublished: jobDropDowns?.isPublished as boolean,
    }

    setDataDropdown(dataDropdowns);
    props.jobValues(dataDropdowns);
  }, [jobDropDowns, youTubeLink]);

  useEffect(() => {
    if (props.action === AlertAction.UPDATE) {
      dataDropdown.companyUrl = correctYouTubeLink(dataDropdown.companyUrl ?? "");
      props.jobValues(dataDropdown);
    }
  }, [props.action]);

  function correctYouTubeLink(link: string): string {
    const regex = /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/\s]{11})/;
    const match = link.match(regex);

    if (match) {
      const videoId = match[1];
      return `https://www.youtube.com/embed/${videoId}`;
    }
    return link;
  }

  return (
    <div className="jobAdvSide">
      <div className="jobAdvSideCompany">
        {companyLogo && <img src={companyLogo} alt="Company logo" />}
        <p>{companyDetails?.address}</p>
      </div>

      <div className="jobAdvSideTitle">{t("responsible")}</div>

      <div className="jobAdvSideUser">
        <div className="jobAdvSideUserName">
          <img src={recrewterProfilePicture} alt="User photo" />
          <div>
            <span>{recrewter?.name}</span>
            <p>{t("recrewter")}</p>
          </div>
        </div>
        <div className="jobAdvSideUserDetails">
          <div>
            <div>
              <FaGlobe />{" "}
              <a href={companyDetails?.website}>{companyDetails?.website}</a>
            </div>
            <div>
              <FaEnvelope />{" "}
              <a href={"mailto:" + companyDetails?.email}>
                {companyDetails?.email}
              </a>
            </div>
          </div>
          <div>
            {recrewter?.title === Title.None && (
              <a
                onClick={() =>
                  navigate(
                    "/chat/" +
                      recrewter?.userId +
                      "/" +
                      t("message.hello.general") +
                      t("message.intentionApply") +
                      '"' +
                      jobTitle +
                      '" ' +
                      t("message.at") +
                      " " +
                      companyDetails?.name +
                      "."
                  )
                }
              >
                <IoChatbubbleEllipsesOutline />
              </a>
            )}
            {recrewter?.title !== Title.None && (
              <a
                onClick={() =>
                  navigate(
                    "/chat/" +
                      recrewter?.userId +
                      "/" +
                      t("message.hello") +
                      Title[recrewter?.title!] +
                      " " +
                      recrewter?.name +
                      ". " +
                      t("message.intentionApply") +
                      '"' +
                      jobTitle +
                      '" ' +
                      t("message.at") +
                      " " +
                      companyDetails?.name +
                      "."
                  )
                }
              >
                <IoChatbubbleEllipsesOutline />
              </a>
            )}
          </div>
        </div>
        <div className="jobAdvSideUserProps">
          {props.editMode || props.jobOfferId === newJobOffer ? (
            <div>
              <DropdownField
                displayName="headerDivision"
                field="division"
                enumType={MedicalDivision}
                selected={jobDropDowns?.division}
                onChange={onChange}
              />
              {/* <DropdownField
                displayName="headerJobType"
                field="jobType"
                enumType={JobType}
                selected={jobDropDowns?.jobType!}
                onChange={onChange}
                disabled={jobDropDowns?.division === 0}
                index={jobDropDowns?.division}
              /> */}
              <DropdownField
                displayName="headerEducationLevel"
                field="educationLevel"
                enumType={EducationLevel}
                selected={jobDropDowns?.educationLevel!}
                onChange={onChange}
                index={jobDropDowns?.educationLevel}
              />
              <DropdownField
                displayName="headerPosition"
                field="jobPosition"
                enumType={JobPosition}
                selected={jobDropDowns?.jobPosition!}
                onChange={onChange}
              />
              <DropdownField
                displayName="headerEmployementType"
                field="employmentType"
                enumType={EmploymentType}
                selected={jobDropDowns?.employmentType!}
                onChange={onChange}
              />
              <DropdownField
                displayName="pensum"
                field="workload"
                enumType={Workload}
                selected={jobDropDowns?.workload!}
                onChange={onChange}
              />
              <DropdownField
                displayName="headerAvailability"
                field="availability"
                enumType={Availability}
                selected={jobDropDowns?.availability!}
                onChange={onChange}
              />
              <DropdownField
                displayName="headerSalary"
                field="salary"
                enumType={Salary}
                selected={jobDropDowns?.salary!}
                onChange={onChange}
              />
              <DropdownField
                displayName="headerCountry"
                field="country"
                enumType={Country}
                selected={jobDropDowns?.country!}
                onChange={onChange}
              />
              {jobDropDowns?.country === Country.Switzerland ? (
                <DropdownField
                  displayName="canton"
                  field="state"
                  enumType={State}
                  selected={jobDropDowns?.state!}
                  onChange={onChange}
                  disabled={jobDropDowns?.country !== Country.Switzerland}
                  index={21600}
                  indexSize={27}
                />
              ) : jobDropDowns?.country === Country.Germany ? (
                <DropdownField
                  displayName="state"
                  field="state"
                  enumType={State}
                  selected={jobDropDowns?.state!}
                  onChange={onChange}
                  disabled={jobDropDowns?.country !== Country.Germany}
                  index={8400}
                  indexSize={17}
                />
              ) : jobDropDowns?.country === Country.Austria ? (
                <DropdownField
                  displayName="state"
                  field="state"
                  enumType={State}
                  selected={jobDropDowns?.state!}
                  onChange={onChange}
                  disabled={jobDropDowns?.country !== Country.Austria}
                  index={1500}
                  indexSize={10}
                />
              ) : (
                <DropdownField
                  displayName="state"
                  field=""
                  enumType={State}
                  selected={0}
                  onChange={onChange}
                  disabled={true}
                />
              )}
            </div>
          ) : jobDropDowns ? (
            <div>
              <div>{t("headerDivision")}:</div>
              <p>{t(MedicalDivision[jobDropDowns.division])}</p>
              {/* <div>{t("headerJobType")}:</div>
              <p>{t(JobType[jobDropDowns.jobType])}</p> */}
              <div>{t("headerEducationLevel")}:</div>
              <p>{t(EducationLevel[jobDropDowns.educationLevel ?? 0])}</p>
              <div>{t("headerPosition")}:</div>
              <p>{t(JobPosition[jobDropDowns.jobPosition ?? 0])}</p>
              <div>{t("headerEmployementType")}:</div>
              <p>{t(EmploymentType[jobDropDowns.employmentType ?? 0])}</p>
              <div>{t("pensum")}:</div>
              <p>{t(Workload[jobDropDowns.workload ?? 0])}</p>
              <div>{t("headerAvailability")}:</div>
              <p>{t(Availability[jobDropDowns.availability ?? 0])}</p>
              <div>{t("headerSalary")}:</div>
              <p>{t(Salary[jobDropDowns.salary ?? 0])}</p>
              <div>{t("headerCountry")}:</div>
              <p>{t(Country[jobDropDowns.country])}</p>
              {jobDropDowns.country === Country.Switzerland ? (
                <>
                  <div>{t("canton")}:</div>
                  <p>{t(State[jobDropDowns.state ?? 0])}</p>
                </>
              ) : (
                <>
                  <div>{t("state")}:</div>
                  <p>{t(State[jobDropDowns.state ?? 0])}</p>
                </>
              )}
            </div>
          ) : undefined}
        </div>
      </div>

      <div className="jobAdvSideTitle">{t("companyVideo")}</div>

      <div className="jobAdvSideVideo">
        <img src={youtubeLogo} alt="YouTube Logo" />
        {props.editMode || props.jobOfferId === newJobOffer ? (
          <TextField
            type="text"
            class="jobAdvInput"
            field=""
            placeholder={t("copyPasteLink")}
            value={youTubeLink}
            onChange={(evnt) =>
              setyouTubeLink(correctYouTubeLink(evnt.target.value))
            }
            onBlur={(evnt) => evnt.currentTarget.value}
            disabled={!recrewter?.isManager}
            onClick={(e) => {
                const target = e.target as HTMLInputElement;
                target.select();
            }}
            // only managers can edit the link
          />
        ) : null}
        <iframe width="100%" height="250px" src={youTubeLink}></iframe>
      </div>
    </div>
  );
}

interface JobOfferCompanyDetailsProperties {
  jobOfferId: string;
  jobValues(job: internalJobDTO): void;
  showAlert(show: boolean): void;
  alertAction(action: AlertAction): void;
  action: AlertAction;
  editMode?: boolean;
}
