import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getCompanyDetailsAPI, getUserInformationAPI } from "../../core/apiFunctions";
import { companyDetailsDTO, userInformationDTO } from "../../core/dto/user.models";
import { formatUrlLink } from "../../core/helperConst";
import { State, Country } from "../../config/Types/PlaceEnumDefinitions";
import "./CVInfo.scss";

export default function CVInfo({
    isEmployee = true,
    ...props
}: CVInfoProperties) {
    const [userInformation, setUserInformation] = useState<userInformationDTO>();
    const [companyDetails, setCompanyDetails] = useState<companyDetailsDTO>();
    const [blurred, setBlurred] = useState<boolean>();
    const { t } = useTranslation();

    useEffect(() => {
        const fetchUserInformation = async () => {
            const userInformation = await getUserInformationAPI(props.userId);
            setUserInformation(userInformation.data);
            setBlurred(userInformation.data.isAnonymized);
        }
        fetchUserInformation();

        if (!isEmployee) {
            const fetchCompanyDetails = async () => {
                // Recrewter should never be blurred
                setBlurred(false);

                getCompanyDetailsAPI(props.userId).then(response => {
                    setCompanyDetails(response.data);
                });
            }
            fetchCompanyDetails();
        }

    }, []);

    useEffect(() => props.onInformationRecieved(userInformation), [userInformation])


    return (
        <>
            <div className="cvCard">
                <div className="cvCardHeader">
                    <p>{isEmployee ? t('myPersonalInfo') : t('aboutCompany')}</p>
                </div>
                <div className={"cvInfo" + (blurred? " cvContentBlured disable-text-selection": "")}>
                    {
                        isEmployee ?
                        <>
                            <div>
                                <p>{t('firstAndLastName')}:</p>
                                <p>{userInformation?.name}</p>
                            </div>
                            <div>
                                <p>{t('eMail')}:</p>
                                <p>{userInformation?.email}</p>
                            </div>
                            <div>
                                <p>{t('phoneNumber')}:</p>
                                <p>{userInformation?.phoneNumber}</p>
                            </div>
                            <div>
                                <p>{t('mobileNumber')}:</p>
                                <p>{userInformation?.mobileNumber}</p>
                            </div>
                            <div>
                                <p>{t('linkedIn')}:</p>
                                <p><a target='_blank' href={formatUrlLink(userInformation?.linkedIn)}>{userInformation?.linkedIn}</a></p>
                            </div>
                            <div>
                                <p>{t('xing')}:</p>
                                <p><a target='_blank' href={formatUrlLink(userInformation?.xing)}>{userInformation?.xing}</a></p>
                            </div>
                            <div>
                                <p>{t('website')}:</p>
                                <p><a target='_blank' href={formatUrlLink(userInformation?.website)}>{userInformation?.website}</a></p>
                            </div>
                        </>
                        :
                        <>
                            <div>
                                <p>{t('name')}:</p>
                                <p>{companyDetails?.name}</p>
                            </div>
                            <div>
                                <p>{t('address')}:</p>
                                <p>{companyDetails?.address}, {companyDetails?.postcode}, {companyDetails?.city}, {State[companyDetails?.state ?? 0]}, {Country[companyDetails?.country ?? 0]}</p>
                            </div>
                            <div>
                                <p>{t('eMail')}:</p>
                                <p>{companyDetails?.email}</p>
                            </div>
                            <div>
                                <p>{t('phoneNumber')}:</p>
                                <p>{companyDetails?.phoneNumber}</p>
                            </div>
                            <div>
                                <p>{t('linkedIn')}:</p>
                                <p><a target='_blank' href={formatUrlLink(companyDetails?.linkedIn)}>{companyDetails?.linkedIn}</a></p>
                            </div>
                            <div>
                                <p>{t('xing')}:</p>
                                <p><a target='_blank' href={formatUrlLink(companyDetails?.xing)}>{companyDetails?.xing}</a></p>
                            </div>
                            <div>
                                <p>{t('website')}:</p>
                                <p><a target='_blank' href={formatUrlLink(companyDetails?.website)}>{companyDetails?.website}</a></p>
                            </div>
                            <div className="youtube-preview">
                                <div>
                                <p>{t('youtube')}:</p>
                                </div>
                                <div>
                                <iframe src={companyDetails?.youTubeUrl}></iframe>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div>
        </>
    )
}

interface CVInfoProperties {
    onInformationRecieved: Function;
    userId: string;
    isEmployee?: boolean;
}