import React, { createContext, useState, useContext, useEffect, ReactNode } from 'react';
import { getProfileHeaderAPI, getProfilePhotoAPI, getCompanyHeaderAPI, getCompanyLogoAPI } from '../core/apiFunctions';
import { getNameIdentifier, getRoleId } from '../core/claimFunctions';
import { getClaims } from '../core/handleJWT';
import { t } from "i18next";
import { useLocation } from 'react-router-dom';
import { ImageType, UserRoles } from '../config/Types/GeneralEnumDefinitions';

const ImageContext = createContext<ImageContextProps | undefined>(undefined);

export const ImageProvider = ({ children }: { children: ReactNode }) => {
    const [images, setImages] = useState<Record<ImageType, string>>({
        [ImageType.ProfileImage]: localStorage.getItem(ImageType.ProfileImage) || '',
        [ImageType.HeaderImage]: localStorage.getItem(ImageType.HeaderImage) || '',
        [ImageType.CompanyLogo]: localStorage.getItem(ImageType.CompanyLogo) || '',
        [ImageType.CompanyHeader]: localStorage.getItem(ImageType.CompanyHeader) || '',
    });

    const [tempImages, setTempImages] = useState<Record<ImageType, string>>({ ...images });

    const [loading, setLoading] = useState<Record<ImageType, boolean>>({
        [ImageType.ProfileImage]: true,
        [ImageType.HeaderImage]: true,
        [ImageType.CompanyLogo]: true,
        [ImageType.CompanyHeader]: true,
    });

    const location = useLocation();

    const fetchImageFromServer = async (
        apiCall: () => Promise<any>,
        imageType: ImageType
    ) => {
        try {
            const response = await apiCall();
            if (response && response.data !== null) {
                const imageUrl = URL.createObjectURL(response.data);
                setImages((prev) => ({ ...prev, [imageType]: imageUrl }));
                setTempImages((prev) => ({ ...prev, [imageType]: imageUrl }));
                localStorage.setItem(imageType, imageUrl);
            }
        } catch (error) {
            console.error(t("failedLoadImageServer"), error);
        } finally {
            setLoading((prev) => ({ ...prev, [imageType]: false }));
        }
    };

    useEffect(() => {
        const claims = getClaims();
        if (!claims || claims.length === 0) {
            setLoading({
                [ImageType.ProfileImage]: false,
                [ImageType.HeaderImage]: false,
                [ImageType.CompanyLogo]: false,
                [ImageType.CompanyHeader]: false,
            });
            return;
        }

        const loadImage = (imageType: ImageType, fetchFunction: () => Promise<any>) => {
            const cachedImage = localStorage.getItem(imageType) ?? '';
            const image = new Image();
            image.src = cachedImage;
            image.onload = () => {
                setImages((prev) => ({ ...prev, [imageType]: cachedImage }));
                setTempImages((prev) => ({ ...prev, [imageType]: cachedImage }));
                setLoading((prev) => ({ ...prev, [imageType]: false }));
            };
            image.onerror = () => {
                fetchImageFromServer(fetchFunction, imageType);
            };
        };

        const userId = getNameIdentifier(claims);

        loadImage(ImageType.ProfileImage, () => getProfilePhotoAPI(userId));
        loadImage(ImageType.HeaderImage, () => getProfileHeaderAPI(userId));

        const role = getRoleId(claims);
        if (role === UserRoles.Recrewter) {
            loadImage(ImageType.CompanyLogo, getCompanyLogoAPI);
            loadImage(ImageType.CompanyHeader, getCompanyHeaderAPI);
        }
    }, [location]);

    const setImage = (imageType: ImageType, image: string) => {
        setTempImages((prev) => ({ ...prev, [imageType]: image }));
    };

    const saveImage = (imageType: ImageType) => {
        setImages((prev) => ({ ...prev, [imageType]: tempImages[imageType] }));
        localStorage.setItem(imageType, tempImages[imageType]);
    };

    const cancelImageChange = (imageType: ImageType) => {
        setTempImages((prev) => ({ ...prev, [imageType]: images[imageType] }));
    };

    return (
        <ImageContext.Provider value={{ images: tempImages, setImage, saveImage, cancelImageChange, loading }}>
            {children}
        </ImageContext.Provider>
    );
};

export const useProfileImage = (): ImageContextProps => {
    const context = useContext(ImageContext);
    if (!context) {
        throw new Error('useProfileImage must be used within a ProfileImageProvider');
    }
    return context;
};

interface ImageContextProps {
    images: Record<ImageType, string>;
    setImage: (imageType: ImageType, image: string) => void;
    saveImage: (imageType: ImageType) => void;
    cancelImageChange: (imageType: ImageType) => void;
    loading: Record<ImageType, boolean>;
}
