import { authenticationResponse, claim } from "./dto/auth.models";

const tokenKey = "token";
const expirationKey = "token-expiration";
const profilePictureKey = "profile-picture";
const headerPictureKey = "header-image";
const companyKey = "company-name";
const languageKey = "i18nextLng";

export function saveToken(authData: authenticationResponse) {
    localStorage.setItem(tokenKey, authData.token);
    localStorage.setItem(expirationKey, authData.expiration.toString());
}

export function getClaims(): claim[] {
    const token = localStorage.getItem(tokenKey);

    if (!token) {
        return [];
    }

    const expiration = localStorage.getItem(expirationKey)!;
    const expirationDate = new Date(expiration);

    if (expirationDate <= new Date()) {
        logout();
        return []; // the token has expired
    }

    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace('-', '+').replace('_', '/');
    const dataToken = JSON.parse(atob(base64));
    const response: claim[] = [];
    for (const property in dataToken) {
        response.push({ name: property, value: dataToken[property] });
    }

    return response;
}

export function logout() {
    if (localStorage.getItem(profilePictureKey) != null) localStorage.removeItem(profilePictureKey);
    if (localStorage.getItem(headerPictureKey) != null) localStorage.removeItem(headerPictureKey);
    if (localStorage.getItem(tokenKey) != null) localStorage.removeItem(tokenKey);
    if (localStorage.getItem(companyKey) != null) localStorage.removeItem(companyKey);
    if (localStorage.getItem(languageKey) != null) localStorage.removeItem(languageKey);
    if (localStorage.getItem(expirationKey) != null) localStorage.removeItem(expirationKey);

    window.location.reload();
}

export function getToken() {
    return localStorage.getItem(tokenKey);
}