import React, { useContext } from "react";
import { Accordion, Card, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { externalJobOfferDTO } from "../../core/dto/dto.models";
import AuthenticationContext from "../../auth/AuthenticationContext";
import { getNameIdentifier } from "../../core/claimFunctions";

export default function JobAccordion({
    data,
    alwaysOpen = false
}: ExternalAccordionProps) {
    const { t } = useTranslation();
    const {claims} = useContext(AuthenticationContext);

    const handleLinkClick = (job: externalJobOfferDTO) => {
        if (window.umami) {
            var userId = getNameIdentifier(claims);
            window.umami.track('External Job Click', { jobId: job.id, url: job.url, userId: userId });
        }
    };

    return (
        <>
            <Accordion
                className="externalJobAccordion"
                alwaysOpen={alwaysOpen}
                defaultActiveKey={data.map(job => job.referencenumber)}
            >
                {data.map((job) => (
                    <Card key={job.id} className="mb-4 shadow bg-secondary rounded-3">
                        <Accordion.Item eventKey={job.id}>
                            <Accordion.Header>
                                <>{job.title}</>
                            </Accordion.Header>
                            <Accordion.Body style={{ padding: "0" }}>
                                <div className="p-3">
                                    <div dangerouslySetInnerHTML={{ __html: job.description }} />
                                    <h3>
                                        <strong>
                                            <a
                                                href={job.url}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                onClick={() => handleLinkClick(job)}
                                            >
                                                {t('checkOutJobsCH')}
                                            </a>
                                        </strong>
                                    </h3>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Card>
                ))}
            </Accordion>
        </>
    );
}

interface ExternalAccordionProps {
    data: externalJobOfferDTO[];
    alwaysOpen?: boolean;
}
