import React from "react"

export default function Button({
    type = "button",
    disabled = false,
    hidden = false,
    ...props
}: buttonProps) {
    return (
        <button
            type={type}
            disabled={disabled}
            className={props.class}
            onClick={props.onClick}
            hidden={hidden}
        >
            {props.children}
        </button>
    );
}

interface buttonProps {
    children?: React.ReactNode;
    onClick?(): void;
    type?: "button" | "submit";
    class?: string;
    disabled?: boolean;
    hidden?: boolean;
}
