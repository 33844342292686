import { ErrorMessage, Field } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import './FormikTextField.scss'

export default function FormikTextField({
    type = 'text',
    class: className = 'mb-3',
    autoFocus = false,
    isRequired = "true",
    ...props
}: textFieldProps) {
    const { t } = useTranslation();
    const [showPassword, setShowPassword] = useState(false);
    const togglePassword = () => {
        setShowPassword(!showPassword);
    };
    const inputType = type === 'password' && !showPassword ? 'password' : 'text';

    return (
        <div className={`${className}`}>
            {props.icon && <img src={props.icon} alt={props.iconAlt} />}

            <div className={`input-wrapper form-floating`}>
                <Field
                    autoFocus={autoFocus}
                    as={props.as}
                    isrequired={isRequired}
                    type={inputType}
                    className={`form-control ${type === 'password' ? 'with-icon' : ''}`}
                    id={props.field}
                    name={props.field}
                    placeholder={props.placeholder}
                />

                {type === 'password' && (
                    <span className="input-icon" onClick={togglePassword}>
                        {showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
                    </span>
                )}

                <label htmlFor={props.field} className="floating-label">
                    {props.displayName ? t(props.displayName) : ""}
                </label>
            </div>

            <ErrorMessage name={props.field}>
                {msg => <div className="text-danger">{msg}</div>}
            </ErrorMessage>
        </div>
    );
}

interface textFieldProps {
    autoFocus?: boolean;
    field: string;
    placeholder?: string;
    displayName?: string;
    icon?: string;
    iconAlt?: string;
    type?: 'text' | 'password';
    class?: string;
    isRequired?: string;
    as?: string;
}
